<template>
  <div class="form-pruefprotokoll">
    <div v-if="this.mainFormActive">

      <form @submit.prevent="sendForm">
        <fieldset class="uk-fieldset active">

          <div class="inner">

            <div class="uk-grid uk-grid-stack">

              <div class="uk-width-1-3@m">
                <input class="uk-input uk-margin-bottom" name="filiale" type="text" placeholder="Filiale"
                  aria-label="Filiale" v-model="this.form.filiale" required>
              </div>

              <div class="uk-width-1-3@m">
                <input class="uk-input uk-margin-bottom" name="datum" type="text" placeholder="TT.MM.YYYY"
                  aria-label="Datum" v-model="this.form.datum" required>
              </div>

              <div class="uk-width-1-3@m">
                <input class="uk-input uk-margin-bottom" name="tnummer" type="text" placeholder="T-Nummer"
                  aria-label="T-Nummer" v-model="this.form.tnummer" required>
              </div>

            </div>

            <div class="uk-grid uk-grid-stack">

              <div class="infobox uk-margin-bottom uk-width-1@m">
                <div class="inner">
                  <p>Die Wartung darf nur durch eine sachkundige Personen durchgeführt werden. Sollte durch die
                    sachkundige Person im Umfang der Wartung festgestellt werden, dass mit einem Austritt von Kältemittel
                    gerechnetAbstimmung mit der Lidl Filalleitung eine angemessene Kennzeichung des Bereiches, von der
                    sachkundigen Person durchgeführt werden, bevor die Arbeiten fortgesetzt werden.</p>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 1</p>
                <div class="uk-margin-bottom">
                  <label>Äußerlich Überprüfung des Aggregats und der Rohrleitungen auf Verschmutzung, Beschädigung und
                    Korrosion:</label>
                  <input v-model="form.nr1" class="uk-radio" type="radio" name="nr1" value="Nein"
                    required><label>NEIN</label>
                  <input v-model="form.nr1" class="uk-radio" type="radio" name="nr1" value="Ja"><label>JA</label>
                </div>

                <div v-if="this.form.nr1 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr1_anmerkung" v-model="form.nr1_anmerkung" required></textarea>
                </div>

              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 2</p>
                <div>
                  <label>L1</label>
                  <input class="uk-input" name="nr2a" type="text" placeholder=" ........A" aria-label="nr2"
                    v-model="this.form.nr2a" required>
                  <label>L2</label>
                  <input class="uk-input" name="nr2b" type="text" placeholder=" ........A" aria-label="nr2"
                    v-model="this.form.nr2b" required>
                  <label>L3</label>
                  <input class="uk-input" name="nr2c" type="text" placeholder=" ........A" aria-label="nr2"
                    v-model="this.form.nr2c" required>
                </div>
                <div class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_anmerkung" v-model="form.nr2_anmerkung"></textarea>
                </div>

              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 3</p>
                <div class="uk-margin-bottom">
                  <label>Kondensatorventilatoren auf Funktion und Geräusche prüfen:</label>
                  <input v-model="form.nr3" class="uk-radio" type="radio" name="nr3" value="Nein"
                    required><label>NEIN</label>
                  <input v-model="form.nr3" class="uk-radio" type="radio" name="nr3" value="Ja"><label>JA</label>
                </div>

                <div v-if="this.form.nr3 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr3_anmerkung" v-model="form.nr3_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 4</p>
                <div class="uk-margin-bottom">
                  <label>Kondensator reinigen:</label>
                  <div class="select_container">
                    <input v-model="form.nr4" class="uk-radio" type="radio" name="nr4" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr4" class="uk-radio" type="radio" name="nr4" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr4 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr4_anmerkung" v-model="form.nr4_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 5</p>
                <div class="uk-margin-bottom">
                  <label>Verdampferventilatoren auf Funktion ( Umluft ) und Geräusche prüfen:</label>
                  <input v-model="form.nr5" class="uk-radio" type="radio" name="nr5" value="Nein"
                    required><label>NEIN</label>
                  <input v-model="form.nr5" class="uk-radio" type="radio" name="nr5" value="Ja"><label>JA</label>
                </div>

                <div v-if="this.form.nr5 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr5_anmerkung" v-model="form.nr5_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 6</p>
                <div class="uk-margin-bottom">
                  <label>Verdunstungstasse, Tropfwanne und Tauwasserablaufschlauch auf Verschmutzung und Beschädigung
                    prüfen und gegebenenfalls reinigen:</label>
                  <input v-model="form.nr6" class="uk-radio" type="radio" name="nr6" value="Nein"
                    required><label>NEIN</label>
                  <input v-model="form.nr6" class="uk-radio" type="radio" name="nr6" value="Ja"><label>JA</label>
                </div>

                <div v-if="this.form.nr6 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr6_anmerkung" v-model="form.nr6_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 7</p>
                <div class="uk-margin-bottom">
                  <label>Beleuchtung auf Funktion prüfen (falls Vorhanden):</label>
                  <input v-model="form.nr7" class="uk-radio" type="radio" name="nr7" value="Nein"
                    required><label>NEIN</label>
                  <input v-model="form.nr7" class="uk-radio" type="radio" name="nr7" value="Ja"><label>JA</label>
                </div>

                <div v-if="this.form.nr7 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr7_anmerkung" v-model="form.nr7_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 8</p>
                <div class="uk-margin-bottom">
                  <label>Kühlraumtemperatur prüfen und dokumentieren:</label>
                  <input class="uk-input" name="nr8" type="text" placeholder=".....° C" aria-label="nr8"
                    v-model="this.form.nr8" required>
                </div>

                <div v-if="this.form.nr8 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr8_anmerkung" v-model="form.nr8_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 9</p>
                <div class="uk-margin-bottom">
                  <label>Kontrolle des Tür- Torkontaktschalter:</label>
                  <div class="select_container">
                    <input v-model="form.nr9" class="uk-radio" type="radio" name="nr9" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr9" class="uk-radio" type="radio" name="nr9" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr9 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr9_anmerkung" v-model="form.nr9_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 10</p>
                <div class="uk-margin-bottom">
                  <label>Kontrolle Tür / Tor Mechanik:</label>
                  <div class="select_container">
                    <input v-model="form.nr10" class="uk-radio" type="radio" name="nr10" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr10" class="uk-radio" type="radio" name="nr10" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr10 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr10_anmerkung" v-model="form.nr10_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 11</p>
                <div class="uk-margin-bottom">
                  <label>Kontrolle Tür / Tor Leichtgängigkeit:</label>
                  <div class="select_container">
                    <input v-model="form.nr11" class="uk-radio" type="radio" name="nr11" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr11" class="uk-radio" type="radio" name="nr11" value="Ja"><label>JA</label>
                  </div>
                </div>


                <div v-if="this.form.nr11 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="1100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr11_anmerkung" v-model="form.nr11_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 12</p>
                <div class="uk-margin-bottom">
                  <label>Kühlzelle auf äusserliche Beschädigungen prüfen, Paneele, Tür / Tor, Rammschutz und festen
                    Sitz.</label>
                  <div class="select_container">
                    <input v-model="form.nr12" class="uk-radio" type="radio" name="nr12" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr12" class="uk-radio" type="radio" name="nr12" value="Ja"><label>JA</label>
                  </div>
                </div>


                <div v-if="this.form.nr12 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr12_anmerkung" v-model="form.nr12_anmerkung" required></textarea>
                </div>

              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 13</p>
                <div class="uk-margin-bottom">
                  <label>Sichtprüfung des Schutzgitters auf äußerliche Beschädigungen und festen Sitz.</label>
                  <div class="select_container">
                    <input v-model="form.nr13" class="uk-radio" type="radio" name="nr13" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr13" class="uk-radio" type="radio" name="nr13" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr13 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr13_anmerkung" v-model="form.nr13_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 14</p>
                <div class="uk-margin-bottom">
                  <label>Sichtprüfung auf Feuchtigkeit</label>
                  <div class="select_container">
                    <input v-model="form.nr14" class="uk-radio" type="radio" name="nr14" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr14" class="uk-radio" type="radio" name="nr14" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr14 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr14_anmerkung" v-model="form.nr14_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 15</p>
                <div class="uk-margin-bottom">
                  <label>elektrische Verbindungen auf festen Sitz prüfen</label>
                  <div class="select_container">
                    <input v-model="form.nr15" class="uk-radio" type="radio" name="nr15" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr15" class="uk-radio" type="radio" name="nr15" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr15 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr15_anmerkung" v-model="form.nr15_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 16</p>
                <div class="uk-margin-bottom">
                  <label>Alarm-Probe Tür</label>
                  <div class="select_container">
                    <input v-model="form.nr16" class="uk-radio" type="radio" name="nr16" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr16" class="uk-radio" type="radio" name="nr16" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr16 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr16_anmerkung" v-model="form.nr16_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 17</p>
                <div class="uk-margin-bottom">
                  <label>Alarm-Probe Temperatur</label>
                  <div class="select_container">
                    <input v-model="form.nr17" class="uk-radio" type="radio" name="nr17" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr17" class="uk-radio" type="radio" name="nr17" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr17 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr17_anmerkung" v-model="form.nr17_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 18</p>
                <div class="uk-margin-bottom">
                  <label>Wartung Propansensor</label>
                  <div class="select_container">
                    <input v-model="form.nr18" class="uk-radio" type="radio" name="nr18" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr18" class="uk-radio" type="radio" name="nr18" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr18 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr18_anmerkung" v-model="form.nr18_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 19</p>
                <div class="uk-margin-bottom">
                  <label>Überprüfung Ablauf Einsatzzeit des Propansensors</label>
                  <div class="select_container">
                    <input v-model="form.nr19" class="uk-radio" type="radio" name="nr19" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr19" class="uk-radio" type="radio" name="nr19" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr19 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr19_anmerkung" v-model="form.nr19_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 20</p>
                <div class="uk-margin-bottom">
                  <label>Hardwaretest aktivieren</label>
                  <div class="select_container">
                    <input v-model="form.nr20" class="uk-radio" type="radio" name="nr20" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr20" class="uk-radio" type="radio" name="nr20" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr20 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="200" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr20_anmerkung" v-model="form.nr20_anmerkung" required></textarea>
                </div>
              </div>



              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 21</p>
                <div class="uk-margin-bottom">
                  <label>S1 - Knopf betätigen -> grüne LED leuchtet</label>
                  <div class="select_container">
                    <input v-model="form.nr21" class="uk-radio" type="radio" name="nr21" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr21" class="uk-radio" type="radio" name="nr21" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr21 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="2100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr21_anmerkung" v-model="form.nr21_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 22</p>
                <div class="uk-margin-bottom">
                  <label>S1 - Knopf erneut betätigen -> gelbe LED leuchtet</label>
                  <div class="select_container">
                    <input v-model="form.nr22" class="uk-radio" type="radio" name="nr22" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr22" class="uk-radio" type="radio" name="nr22" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr22 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr22_anmerkung" v-model="form.nr22_anmerkung" required></textarea>
                </div>

              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 23</p>
                <div class="uk-margin-bottom">
                  <label>S1 - Knopf erneut betätigen -> rote LED leuchtet</label>
                  <div class="select_container">
                    <input v-model="form.nr23" class="uk-radio" type="radio" name="nr23" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr23" class="uk-radio" type="radio" name="nr23" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr23 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr23_anmerkung" v-model="form.nr23_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 24</p>
                <div class="uk-margin-bottom">
                  <label>S1 - Knopf erneut betätigen -> Summton erklingt</label>
                  <div class="select_container">
                    <input v-model="form.nr24" class="uk-radio" type="radio" name="nr24" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr24" class="uk-radio" type="radio" name="nr24" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr24 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr24_anmerkung" v-model="form.nr24_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 25</p>
                <div class="uk-margin-bottom">
                  <label>S1 - Knopf erneut betätigen -> K1-Relais aktiviert den Alarm</label>
                  <div class="select_container">
                    <input v-model="form.nr25" class="uk-radio" type="radio" name="nr25" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr25" class="uk-radio" type="radio" name="nr25" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr25 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr25_anmerkung" v-model="form.nr25_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 26</p>
                <div class="uk-margin-bottom">
                  <label>S1 - Knopf erneut betätigen -> K2-Relais aktiviert den Alarm</label>
                  <div class="select_container">
                    <input v-model="form.nr26" class="uk-radio" type="radio" name="nr26" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr26" class="uk-radio" type="radio" name="nr26" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr26 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr26_anmerkung" v-model="form.nr26_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 27</p>
                <div class="uk-margin-bottom">
                  <label>S1 - Knopf erneut betätigen -> Der Summton wird kurzzeitig aktiviert und das grüne LED
                    leuchtet</label>
                  <div class="select_container">
                    <input v-model="form.nr27" class="uk-radio" type="radio" name="nr27" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr27" class="uk-radio" type="radio" name="nr27" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr27 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr27_anmerkung" v-model="form.nr27_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 28</p>
                <div class="uk-margin-bottom">
                  <label>Sensor Test</label>
                  <div class="select_container">
                    <input v-model="form.nr28" class="uk-radio" type="radio" name="nr28" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr28" class="uk-radio" type="radio" name="nr28" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr28 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr28_anmerkung" v-model="form.nr28_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 29</p>
                <div class="uk-margin-bottom">
                  <label>Wartung Sicherheitspaket durchführen</label>
                  <div class="select_container">
                    <input v-model="form.nr29" class="uk-radio" type="radio" name="nr29" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr29" class="uk-radio" type="radio" name="nr29" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr29 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr29_anmerkung" v-model="form.nr29_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 30</p>
                <div class="uk-margin-bottom">
                  <label>Serviceaufkleber vorhanden</label>
                  <div class="select_container">
                    <input v-model="form.nr30" class="uk-radio" type="radio" name="nr30" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr30" class="uk-radio" type="radio" name="nr30" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr30 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="300" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr30_anmerkung" v-model="form.nr30_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">Nr. 31</p>
                <div class="uk-margin-bottom">
                  <label>Prüfplakette anbringen</label>
                  <div class="select_container">
                    <input v-model="form.nr31" class="uk-radio" type="radio" name="nr31" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr31" class="uk-radio" type="radio" name="nr31" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr31 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="310" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr31_anmerkung" v-model="form.nr31_anmerkung" required></textarea>
                </div>
              </div>


              <div class="field_container uk-width-1@s">
                <div>
                  <label>Bemerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Bemerkungen"
                    name="bemerkungen" v-model="form.bemerkungen"></textarea>
                </div>
              </div>

            </div>

            <div class="uk-grid uk-grid-stack uk-margin-bottom">

              <div class="uk-width-1-2@m">
                <input class="uk-input uk-margin-bottom" name="marktleiter_name" type="text"
                  placeholder="Name Marktleiter (BLOCKBUCHSTABEN)" aria-label="Name Marktleiter (BLOCKBUCHSTABEN)"
                  v-model="this.form.marktleiter_name" required>
              </div>

              <div class="uk-width-1-2@m">
                <input class="uk-input" name="servicetechniker_name" type="text"
                  placeholder="Name Service Techniker (BLOCKBUCHSTABEN)"
                  aria-label="Name Service Techniker (BLOCKBUCHSTABEN)" v-model="this.form.servicetechniker_name"
                  required>
              </div>

            </div>

          </div>

          <div class="form_buttons">
            <button class="uk-button uk-button-primary uk-button-small submit-btn">Bearbeitung abschliessen</button>
          </div>

          <div v-if="this.showHint == true" class="submit-check-hint">
            <p>Überprüfen Sie bitte noch mal alle Ihre Daten auf korrekte Eingabe. Eine nachträgliche Bearbeitung ist
              nur vom Büro möglich.</p>
            <button @click="backToForm()">Zurück zur Bearbeitung</button>
            <button @click="sendFormFinal()">Formular absenden</button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>

  <!-- 1 -->
  <div v-if="this.mainFormActive != true">
    <div class="form_success_screen uk-grid">
      <div class="uk-width-1-1@m">
        <h3 class="status">{{ this.status }}</h3>
        <div class="centered button_container">
          <button @click="backToOverview">Zurück zur Formularauswahl</button>
        </div>
      </div>
    </div>
  </div>
  <!-- 1 -->
</template>

<script>

import axios from 'axios'
import { reactive } from 'vue'

export default {
  name: 'FormPruefprotokoll',

  setup() {
    // make users variable reactive with the ref() function


    const form = reactive(

      {
        form_title: "NK-Zelle mit Monoblock R290 mit Schiebetüren",
        filiale: "",
        datum: null,
        userid: 1,

        tnummer: "",

        nr1: "",
        nr2a: "",
        nr2b: "",
        nr2c: "",
        nr3: "",
        nr4: "",
        nr5: "",
        nr6: "",
        nr7: "",
        nr8: "",
        nr9: "",
        nr10: "",
        nr11: "",
        nr12: "",
        nr13: "",
        nr14: "",
        nr15: "",
        nr16: "",
        nr17: "",
        nr18: "",
        nr19: "",
        nr20: "",
        nr21: "",
        nr22: "",
        nr23: "",
        nr24: "",
        nr25: "",
        nr26: "",
        nr27: "",
        nr28: "",
        nr29: "",
        nr30: "",
        nr31: "",

        nr1_anmerkung: "",
        nr2_anmerkung: "",
        nr3_anmerkung: "",
        nr4_anmerkung: "",
        nr5_anmerkung: "",
        nr6_anmerkung: "",
        nr7_anmerkung: "",
        nr8_anmerkung: "",
        nr9_anmerkung: "",
        nr10_anmerkung: "",
        nr11_anmerkung: "",
        nr12_anmerkung: "",
        nr13_anmerkung: "",
        nr14_anmerkung: "",
        nr15_anmerkung: "",
        nr16_anmerkung: "",
        nr17_anmerkung: "",
        nr18_anmerkung: "",
        nr19_anmerkung: "",
        nr20_anmerkung: "",
        nr21_anmerkung: "",
        nr22_anmerkung: "",
        nr23_anmerkung: "",
        nr24_anmerkung: "",
        nr25_anmerkung: "",
        nr26_anmerkung: "",
        nr27_anmerkung: "",
        nr28_anmerkung: "",
        nr29_anmerkung: "",
        nr30_anmerkung: "",
        nr31_anmerkung: "",
        bemerkungen: "",
        marktleiter_name: "",
        servicetechniker_name: ""
      }
    );




    /*
    const form = reactive(
      
        { 
          form_title: "NK-Zelle mit Monoblock R290 mit Schiebetüren",
          filiale: "Meine Filiale",
          datum: "09.08.2023",
          userid: 1,
          tnummer: "t0000",

          nr1: "Nein",
          nr2a: "Nein",
          nr2b: "Nein",
          nr2c: "Nein",
          nr3: "Nein",
          nr4: "Nein",
          nr5: "Nein",
          nr6: "Nein",
          nr7: "Nein",
          nr8: "Nein",
          nr9: "Nein",
          nr10: "Nein",
          nr11: "Nein",
          nr12: "Nein",
          nr13: "Nein",
          nr14: "Nein",
          nr15: "Nein",
          nr16: "Nein",
          nr17: "Nein",
          nr18: "Nein",
          nr19: "Nein",
          nr20: "Nein",
          nr21: "Nein",
          nr22: "Nein",
          nr23: "Nein",
          nr24: "Nein",
          nr25: "Nein",
          nr26: "Nein",
          nr27: "Nein",
          nr28: "Nein",
          nr29: "Nein",
          nr30: "Nein",
          nr31: "Nein",

          nr1_anmerkung: "Test 1",
          nr2_anmerkung: "Test 2",
          nr3_anmerkung: "Test 3",
          nr4_anmerkung: "Test 4",
          nr5_anmerkung: "Test 5",
          nr6_anmerkung: "Test 6",
          nr7_anmerkung: "Test 7",
          nr8_anmerkung: "Test 8",
          nr9_anmerkung: "Test 9",
          nr10_anmerkung: "Test 10",
          nr11_anmerkung: "Test 11",
          nr12_anmerkung: "Test 12",
          nr13_anmerkung: "Test 13",
          nr14_anmerkung: "Test 14",
          nr15_anmerkung: "Test 15",
          nr16_anmerkung: "Test 16",
          nr17_anmerkung: "Test 17",
          nr18_anmerkung: "Test 18",
          nr19_anmerkung: "Test 19",
          nr20_anmerkung: "Test 20",
          nr21_anmerkung: "Test 21",
          nr22_anmerkung: "Test 22",
          nr23_anmerkung: "Test 23",
          nr24_anmerkung: "Test 24",
          nr25_anmerkung: "Test 25",
          nr26_anmerkung: "Test 26",
          nr27_anmerkung: "Test 27",
          nr28_anmerkung: "Test 28",
          nr29_anmerkung: "Test 29",
          nr30_anmerkung: "Test 30",
          nr31_anmerkung: "Test 31",
          bemerkungen: "Meine Bemerkung",
          marktleiter_name: "Marktleiter Max",
          servicetechniker_name: "Servicetechniker Fritz"
        }         
      );
    
     */



    return { form }
  },

  data: function () {
    return {
      mainFormActive: true,
      overviewPage: "https://hands4you.codepreview.de/formulare",
      status: "Danke Ihr Auftrag wurde gespeichert.",
      currentID: null,
      currentUser: null,
      showHint: false,

    }
  },

  mounted() {
    this.form.datum = this.getCurrentDate();

    console.log("TEST: " + this.form.datum);

    if (typeof userid !== 'undefined') {
      this.form.userid = userid;
    }

    if (typeof document.getElementById('meta-app-user-lastname') !== undefined && document.getElementById('meta-app-user-lastname') != null) {
      this.currentUser = {
        id: document.getElementById('meta-app-user-lastname').content,
        lastname: document.getElementById('meta-app-user-lastname').content,
        firstname: document.getElementById('meta-app-user-firstname').content
      }
    }

  },

  methods: {
    sendForm: async function () {
      console.log('CHECK NOW');
      this.showHint = true;
    },

    getUserID: async function () {
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/form_arbeitsbericht.php';
      try {
        let payload = JSON.stringify(this.form);
        const { data } = await axios.post(url, payload);
      } catch (e) {
        console.log(e);
      }
    },

    createPDF: async function () {
      console.log("createPDF");
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/generatePDF.php';
      let myData =
      {
        id: this.currentID,
        formular_detail_ansicht_url: "https://hands4you.codepreview.de/formulare/pruefprotokoll_schiebetueren/detailansicht/"
      }

      let payload = JSON.stringify(myData);
      try {
        // const {returnValue} = await axios.post(url, payload);
        // console.log(returnValue);

        axios.post(url,
          payload,
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/pdf'
            }
          })
          .then((response) => {

            // Er erstellt einen Link zu dem PDF und führt dann den Klick auf den Link aus, abgefahren

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let myFileName = "";

            if (this.currentUser != null) {
              myFileName = "pruefprotokoll_schiebetueren_" + this.currentUser.lastname + "_" + this.currentID + ".pdf";
            } else {
              myFileName = "pruefprotokoll_schiebetueren_" + this.currentID + ".pdf";
            }


            link.setAttribute('download', myFileName); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => console.log(error));
        this.status = "Die PDF wurde generiert.";

      } catch (e) {
        console.log(e);
      }
    },

    backToOverview: function () {
      window.open(this.overviewPage, "_self")
    },

    getCurrentDate: function () {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Monate sind 0-basiert
      const year = today.getFullYear();

      return `${day}.${month}.${year}`;
    },


    createFormData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },

    /*
    let url = 'https://hands4you.codepreview.de/files/hands4you/php/form_pruefprotokoll_schiebetueren.php';
     
         
        */

    backToForm: function () {
      this.showHint = false;
    },
    sendFormFinal: async function () {
      console.log("sendFormFinal")
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/form_pruefprotokoll_schiebetueren.php';

      try {
        let payload = JSON.stringify(this.form);
        const { data } = await axios.post(url, payload);
        console.log(data);

        // The script returns the id of the just created dataset
        this.currentID = data;
        console.log("NEW ID: " + this.currentID);

        window.scrollTo(0, 0);
        this.mainFormActive = false;
      } catch (e) {
        console.log(e);
      }
    }


  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$white: #FFF;

.form_title {
  color: $white;
}
</style>
