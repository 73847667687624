<template>
  <div class="form-pruefprotokoll">
    <div v-if="this.mainFormActive">

      <form @submit.prevent="sendForm">


        <fieldset class="uk-fieldset active">
          <div class="inner">
            <div class="uk-grid uk-grid-stack">
              <div class="uk-width-1-3@m">
                <input class="uk-input uk-margin-bottom" name="filiale" type="text" placeholder="Filiale"
                  aria-label="Filiale" v-model="this.form.filiale" required>
              </div>

              <div class="uk-width-1-3@m">
                <input class="uk-input uk-margin-bottom" name="datum" type="text" placeholder="TT.MM.YYYY"
                  aria-label="Datum" v-model="this.form.datum" required>
              </div>

              <div class="uk-width-1-3@m">
                <input class="uk-input uk-margin-bottom" name="tnummer" type="text" placeholder="T-Nummer"
                  aria-label="T-Nummer" v-model="this.form.tnummer" required>
              </div>

            </div>

            <div class="uk-grid uk-grid-stack">

              <h2 class="uk-margin-bottom">1. Pumpenstation</h2>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">1.1</p>
                <div class="uk-margin-bottom">
                  <label>Anlage frei von Verschmutzung, Beschädigung und Korrosion.</label>
                  <div class="select_container">
                    <input v-model="form.nr1_1" class="uk-radio" type="radio" name="nr1_1" value="Nein"
                      required><label>NEIN</label>
  
                    <input v-model="form.nr1_1" class="uk-radio" type="radio" name="nr1_1" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr1_1 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr1_1_anmerkung" v-model="form.nr1_1_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">1.2</p>
                <div class="uk-margin-bottom">
                  <label>Einstellung des Solemischventiles nach Vorgabe?</label>
                  <div class="select_container">
                    <input v-model="form.nr1_2" class="uk-radio" type="radio" name="nr1_2" value="Nein"
                      required><label>NEIN</label>
               
                 
                    <input v-model="form.nr1_2" class="uk-radio" type="radio" name="nr1_2" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div>
                  <p>Wann: Frühjahr</p>
                </div>

                <div v-if="this.form.nr1_2 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr1_2_anmerkung" v-model="form.nr1_2_anmerkung" required></textarea>
                </div>

              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">1.3</p>
                <div class="uk-margin-bottom">
                  <label>Redundanzbetrieb der Pumpen in Ordnung?</label>
                  <div class="select_container">
                    <input v-model="form.nr1_3" class="uk-radio" type="radio" name="nr1_3" value="Nein"
                      required><label>NEIN</label>
                  
                 
                    <input v-model="form.nr1_3" class="uk-radio" type="radio" name="nr1_3" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>

                <div v-if="this.form.nr1_3 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr1_3_anmerkung" v-model="form.nr1_3_anmerkung" required></textarea>
                </div>
              </div>



              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">1.4</p>
                <div class="uk-margin-bottom">
                  <label>Pumpen auf Laufgeräusche prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr1_4" class="uk-radio" type="radio" name="nr1_4" value="Nein"
                      required><label>NEIN</label>
                
                
                    <input v-model="form.nr1_4" class="uk-radio" type="radio" name="nr1_4" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>

                <div v-if="this.form.nr1_4 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr1_4_anmerkung" v-model="form.nr1_4_anmerkung" required></textarea>
                </div>
              </div>


              <h2 class="uk-margin-bottom uk-margin-top">2. Wärmetauscher Rückkühler</h2>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.1</p>
                <div class="uk-margin-bottom">
                  <label>Äußerl. auf Verschmutzung, Beschädigung und Korrosion prüfen und Verflüssiger nass
                    reinigen:</label>
                  <div class="select_container">
                    <input v-model="form.nr2_1" class="uk-radio" type="radio" name="nr2_1" value="Nein"
                      required><label>NEIN</label>
       
                    <input v-model="form.nr2_1" class="uk-radio" type="radio" name="nr2_1" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr2_1 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_1_anmerkung" v-model="form.nr2_1_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.2</p>
                <div class="uk-margin-bottom">
                  <label>Äußerl.auf Verschmutzung, Beschädigung, Korrosion prüfen u.Verflüssiger ggf.trocken
                    reinigen:</label>
                  <div class="select_container">
                    <input v-model="form.nr2_2" class="uk-radio" type="radio" name="nr2_2" value="Nein"
                      required><label>NEIN</label>
       
                    <input v-model="form.nr2_2" class="uk-radio" type="radio" name="nr2_2" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Herbst</p>
                </div>
                <div v-if="this.form.nr2_2 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_2_anmerkung" v-model="form.nr2_2_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.3</p>
                <div class="uk-margin-bottom">
                  <label>Parameter GMM Regler nach Vorgabe Arbeitsanweisung bzw. aktueller Parameterliste?</label>
                  <div class="select_container">
                    <input v-model="form.nr2_3" class="uk-radio" type="radio" name="nr2_3" value="Nein"
                      required><label>NEIN</label>
         
                    <input v-model="form.nr2_3" class="uk-radio" type="radio" name="nr2_3" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr2_3 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_3_anmerkung" v-model="form.nr2_3_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.4</p>
                <div class="uk-margin-bottom">
                  <label>Lüfterfunktion in Ordnung? Notbetrieb funktionsfähig?</label>
                  <div class="select_container">
                    <input v-model="form.nr2_4" class="uk-radio" type="radio" name="nr2_4" value="Nein"
                      required><label>NEIN</label>
         
                    <input v-model="form.nr2_4" class="uk-radio" type="radio" name="nr2_4" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr2_4 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_4_anmerkung" v-model="form.nr2_4_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.5</p>
                <div class="uk-margin-bottom">
                  <input class="uk-input" name="nr2_5" type="text" placeholder="........° C" aria-label="nr2_5"
                    v-model="form.nr2_5" required>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr2_5 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_5_anmerkung" v-model="form.nr2_5_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.6</p>
                <div class="uk-margin-bottom">
                  <label>Lufteinemperatur messen:</label>

                  <input class="uk-input" name="nr2_6a" type="text" placeholder="........° C" aria-label="nr2_6a"
                    v-model="form.nr2_6a" required>

                  <label>Luftaustrittsttemperatur messen:</label>

                  <input class="uk-input" name="nr2_6b" type="text" placeholder="........° C" aria-label="nr2_6b"
                    v-model="form.nr2_6b" required>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr2_6 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_6_anmerkung" v-model="form.nr2_6_anmerkung" required></textarea>
                </div>
              </div>


              <h2 class="uk-margin-bottom uk-margin-top">3. Sole</h2>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">3.1</p>
                <div class="uk-margin-bottom">
                  <label>Soledrück messen</label>
                  <input class="uk-input" name="nr3_1" type="text" placeholder="........ Bar" aria-label="nr3_1"
                    v-model="form.nr3_1" required>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr3_1 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr3_1_anmerkung" v-model="form.nr3_1_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">3.2</p>
                <div class="uk-margin-bottom">
                  <label>Frostschutz der Sole in Ordnung?</label>
                  <input class="uk-input" name="nr3_2" type="text" placeholder="........" aria-label="nr3_2"
                    v-model="form.nr3_2" required>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr3_2 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr3_2_anmerkung" v-model="form.nr3_2_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">3.3</p>

                <div class="uk-margin-bottom">
                  <label>ph-Wert der Sole in Ordnung?</label>
                  <input class="uk-input" name="nr3_3" type="text" placeholder="........" aria-label="nr3_3"
                    v-model="form.nr3_3" required>
                </div>

                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr3_3 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr3_3_anmerkung" v-model="form.nr3_3_anmerkung" required></textarea>
                </div>
              </div>

              <h2 class="uk-margin-bottom uk-margin-top">4. Kühlmöbel</h2>

              <div class="field_container uk-width-1@s">
                <div class="uk-margin-bottom">
                  <p class="field_container_headline">4.1</p>
                  <label>Funktion der Nachtrollos gegeben?</label>
                  <div class="select_container">
                    <input v-model="form.nr4_1" class="uk-radio" type="radio" name="nr4_1" value="Nein"
                      required><label>NEIN</label>
             
                    <input v-model="form.nr4_1" class="uk-radio" type="radio" name="nr4_1" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr4_1 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr4_1_anmerkung" v-model="form.nr4_1_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">4.2</p>
                <div class="uk-margin-bottom">
                  <label>Einstellung der Nachtrollos in Ordnung?</label>
                  <div class="select_container">
                    <input v-model="form.nr4_2" class="uk-radio" type="radio" name="nr4_2" value="Nein"
                      required><label>NEIN</label>
             
                    <input v-model="form.nr4_2" class="uk-radio" type="radio" name="nr4_2" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr4_2 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr4_2_anmerkung" v-model="form.nr4_2_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">4.3</p>
                <div class="uk-margin-bottom">
                  <label>Parameter der Kühlstellenregler nach Vorgabe Arbeitsanweisung?</label>
                  <div class="select_container">
                    <input v-model="form.nr4_3" class="uk-radio" type="radio" name="nr4_3" value="Nein"
                      required><label>NEIN</label>
             
                    <input v-model="form.nr4_3" class="uk-radio" type="radio" name="nr4_3" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr4_3 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr4_3_anmerkung" v-model="form.nr4_3_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">4.4</p>
                <div class="uk-margin-bottom">
                  <label>Äußerlich auf Beschädigung und Korrosion prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr4_4" class="uk-radio" type="radio" name="nr4_4" value="Nein"
                      required><label>NEIN</label>
           
                    <input v-model="form.nr4_4" class="uk-radio" type="radio" name="nr4_4" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr4_4 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr4_4_anmerkung" v-model="form.nr4_4_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">4.5</p>
                <div class="uk-margin-bottom">
                  <label>Luftleitwände und Waben auf Verschmutzung prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr4_5" class="uk-radio" type="radio" name="nr4_5" value="Nein"
                      required><label>NEIN</label>
          
                    <input v-model="form.nr4_5" class="uk-radio" type="radio" name="nr4_5" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr4_5 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr4_5_anmerkung" v-model="form.nr4_5_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">4.6</p>
                <div class="uk-margin-bottom">
                  <label>Tauwasserabläufe auf Funktion prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr4_6" class="uk-radio" type="radio" name="nr4_6" value="Nein"
                      required><label>NEIN</label>
           
                    <input v-model="form.nr4_6" class="uk-radio" type="radio" name="nr4_6" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr4_6 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr4_6_anmerkung" v-model="form.nr4_6_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">4.7</p>
                <div class="uk-margin-bottom">
                  <label>Temperaturkontrolle ( Zuluft ) an jedem Möbelkorpus:</label>
                  <div class="select_container">
                    <input v-model="form.nr4_7" class="uk-radio" type="radio" name="nr4_7" value="Nein"
                      required><label>NEIN</label>
           
                    <input v-model="form.nr4_7" class="uk-radio" type="radio" name="nr4_7" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr4_7 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr4_7_anmerkung" v-model="form.nr4_7_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">4.8</p>
                <div class="uk-margin-bottom">
                  <label>Thermometer auf Funktion, korrekte Fühlerposition und Fühlerbefestigung prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr4_8" class="uk-radio" type="radio" name="nr4_8" value="Nein"
                      required><label>NEIN</label>
             
                    <input v-model="form.nr4_8" class="uk-radio" type="radio" name="nr4_8" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr4_8 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr4_8_anmerkung" v-model="form.nr4_8_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">4.9</p>
                <div class="uk-margin-bottom">
                  <label>Verdampferventilatoren auf Funktion ( Umluft ) und Geräusche prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr4_9" class="uk-radio" type="radio" name="nr4_9" value="Nein"
                      required><label>NEIN</label>
                
                    <input v-model="form.nr4_9" class="uk-radio" type="radio" name="nr4_9" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr4_9 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr4_9_anmerkung" v-model="form.nr4_9_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">4.10</p>
                <div class="uk-margin-bottom">
                  <label>Temperaturen der Kühlmöbel überprüfen & dokumentieren:</label>
                  <div class="datagrid">
                    <div class="inner">
                      <div class="uk-grid-small uk-grid uk-grid-stack uk-width-1-2@s" uk-grid>
                        <div v-for="(item, index) in this.form.temperaturen" :key="item.index">

                          <div class="item">
                            <div class="inner">


                              <div class="field_container uk-width-1-3@m">
                                <label style="display: inline-block; margin-right: 5px;">Korpus {{ index + 1 }}</label>
                                <div style="display: flex; align-items: center;">
                                  <input class="uk-input" type="text" name="temperatur" placeholder="Temperatur"
                                    aria-label="Temperatur" v-model="item.temperatur">
                                  <label style="margin-left: 5px;">°C</label>
                                </div>
                              </div>

                            </div>


                            <div class="grid_nav nav-container m-top-150">
                              <button @click="temperaturen_addListItem($event, form.temperaturen)"
                                class="uk-button uk-button-secondary uk-button-small uk-margin-small-right uk-margin-bottom"><span
                                  class="uk-icon" uk-icon="plus-circle"></span>Neuen Eintrag hinzufügen</button>
                              <button @click="temperaturen_removeListItem($event, form.temperaturen, item, index)"
                                class="uk-button uk-button-secondary uk-button-small uk-margin-bottom"><span
                                  class="uk-icon" uk-icon="minus-circle"></span>Eintrag löschen</button>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr4_10 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr4_10_anmerkung" v-model="form.nr4_10_anmerkung" required></textarea>
                </div>
              </div>


              <h2 class="uk-margin-bottom uk-margin-top">5. Rohrleitungen</h2>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">5.1</p>
                <div class="uk-margin-bottom">
                  <label>Isolierung äußerlich auf Beschädigung prüfen</label>
                  <div class="select_container">
                    <input v-model="form.nr5_1" class="uk-radio" type="radio" name="nr5_1" value="Nein"
                      required><label>NEIN</label>
             
                    <input v-model="form.nr5_1" class="uk-radio" type="radio" name="nr5_1" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr5_1 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr5_1_anmerkung" v-model="form.nr5_1_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">5.2</p>
                <div class="uk-margin-bottom">
                  <label>Äußerlich auf Verschmutzung, Beschädigung und Korrosion prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr5_2" class="uk-radio" type="radio" name="nr5_2" value="Nein"
                      required><label>NEIN</label>
               
                    <input v-model="form.nr5_2" class="uk-radio" type="radio" name="nr5_2" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr5_2 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr5_2_anmerkung" v-model="form.nr5_2_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">5.3</p>
                <div class="uk-margin-bottom">
                  <label>Rohrleitungen auf Befestigung prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr5_3" class="uk-radio" type="radio" name="nr5_3" value="Nein"
                      required><label>NEIN</label>
           
                    <input v-model="form.nr5_3" class="uk-radio" type="radio" name="nr5_3" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr5_3 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr5_3_anmerkung" v-model="form.nr5_3_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">5.4</p>
                <div class="uk-margin-bottom">
                  <label>Absperrventile auf Funktion prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr5_4" class="uk-radio" type="radio" name="nr5_4" value="Nein"
                      required><label>NEIN</label>
                
                    <input v-model="form.nr5_4" class="uk-radio" type="radio" name="nr5_4" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr5_4 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr5_4_anmerkung" v-model="form.nr5_4_anmerkung" required></textarea>
                </div>
              </div>

              <h2 class="uk-margin-bottom uk-margin-top">6. Elektrische Einrichtungen Schaltschrank</h2>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">6.1</p>
                <div class="uk-margin-bottom">
                  <label>Schalt- und Sicherheitskette auf Funktion prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr6_1" class="uk-radio" type="radio" name="nr6_1" value="Nein"
                      required><label>NEIN</label>
             
                    <input v-model="form.nr6_1" class="uk-radio" type="radio" name="nr6_1" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr6_1 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr6_1_anmerkung" v-model="form.nr6_1_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">6.2</p>
                <div class="uk-margin-bottom">
                  <label>Optische und akustische Kontrolleinrichtungen prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr6_2" class="uk-radio" type="radio" name="nr6_2" value="Nein"
                      required><label>NEIN</label>
                
                    <input v-model="form.nr6_2" class="uk-radio" type="radio" name="nr6_2" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr6_2 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr6_2_anmerkung" v-model="form.nr6_2_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">6.3</p>
                <div class="uk-margin-bottom">
                  <label>Auf Verschmutzung und Beschädigung prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr6_3" class="uk-radio" type="radio" name="nr6_3" value="Nein"
                      required><label>NEIN</label>
              
                    <input v-model="form.nr6_3" class="uk-radio" type="radio" name="nr6_3" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr6_3 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr6_3_anmerkung" v-model="form.nr6_3_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">6.4</p>
                <div class="uk-margin-bottom">
                  <label>Schutzabdeckungen und Schutzkappen auf Vollständigkeit und Funktion prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr6_4" class="uk-radio" type="radio" name="nr6_4" value="Nein"
                      required><label>NEIN</label>
                
                    <input v-model="form.nr6_4" class="uk-radio" type="radio" name="nr6_4" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr6_4 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr6_4_anmerkung" v-model="form.nr6_4_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">6.5</p>
                <div class="uk-margin-bottom">
                  <label>Anschlussklemmen auf festen Sitz prüfen und ggf. nachziehen:</label>
                  <div class="select_container">
                    <input v-model="form.nr6_5" class="uk-radio" type="radio" name="nr6_5" value="Nein"
                      required><label>NEIN</label>
            
                    <input v-model="form.nr6_5" class="uk-radio" type="radio" name="nr6_5" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr6_5 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr6_5_anmerkung" v-model="form.nr6_5_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">6.6</p>
                <div class="uk-margin-bottom">
                  <label>Schütze und Relais auf Beschädigung prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr6_6" class="uk-radio" type="radio" name="nr6_6" value="Nein"
                      required><label>NEIN</label>
             
                    <input v-model="form.nr6_6" class="uk-radio" type="radio" name="nr6_6" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr6_6 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr6_6_anmerkung" v-model="form.nr6_6_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">6.7</p>
                <div class="uk-margin-bottom">
                  <label>Schalter und Taster auf Funktion prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr6_7" class="uk-radio" type="radio" name="nr6_7" value="Nein"
                      required><label>NEIN</label>
           
                    <input v-model="form.nr6_7" class="uk-radio" type="radio" name="nr6_7" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr6_7 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr6_7_anmerkung" v-model="form.nr6_7_anmerkung" required></textarea>
                </div>
              </div>

              <h2 class="uk-margin-bottom uk-margin-top">7. Abfrage Dachaufstieg, Absturzsicherung</h2>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">7.1</p>
                <div class="uk-margin-bottom">
                  <label>Ist eine Absturzsicherung vorhanden?</label>
                  <div class="select_container">
                    <input v-model="form.nr7_1" class="uk-radio" type="radio" name="nr7_1" value="Nein"
                      required><label>NEIN</label>
             
                    <input v-model="form.nr7_1" class="uk-radio" type="radio" name="nr7_1" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr7_1 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr7_1_anmerkung" v-model="form.nr7_1_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">7.2</p>
                <div class="uk-margin-bottom">
                  <label>Ist der Dachzugang gewährleistet?</label>
                  <div class="select_container">
                    <input v-model="form.nr7_2" class="uk-radio" type="radio" name="nr7_2" value="Nein"
                      required><label>NEIN</label>
            
                    <input v-model="form.nr7_2" class="uk-radio" type="radio" name="nr7_2" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr7_2 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr7_2_anmerkung" v-model="form.nr7_2_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">7.3</p>
                <div class="uk-margin-bottom">
                  <label>Ist die Erreichbarkeit des Außengerätes gegeben?</label>
                  <div class="select_container">
                    <input v-model="form.nr7_3" class="uk-radio" type="radio" name="nr7_3" value="Nein"
                      required><label>NEIN</label>
             
                    <input v-model="form.nr7_3" class="uk-radio" type="radio" name="nr7_3" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr7_3 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr7_3_anmerkung" v-model="form.nr7_3_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">7.4</p>
                <div class="uk-margin-bottom">
                  <label>Kann der Rückkühler gefahrlos gereingt werden?</label>
                  <div class="select_container">
                    <input v-model="form.nr7_4" class="uk-radio" type="radio" name="nr7_4" value="Nein"
                      required><label>NEIN</label>
             
                    <input v-model="form.nr7_4" class="uk-radio" type="radio" name="nr7_4" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr7_4 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr7_4_anmerkung" v-model="form.nr7_4_anmerkung" required></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">7.5</p>
                <div class="uk-margin-bottom">
                  <label>Ist ein Schlüssel vorhanden?</label>
                  <div class="select_container">
                    <input v-model="form.nr7_5" class="uk-radio" type="radio" name="nr7_5" value="Nein"
                      required><label>NEIN</label>
             
                    <input v-model="form.nr7_5" class="uk-radio" type="radio" name="nr7_5" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr/Herbst</p>
                </div>
                <div v-if="this.form.nr7_5 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr7_5_anmerkung" v-model="form.nr7_5_anmerkung" required></textarea>
                </div>
              </div>


              <h2 class="uk-margin-bottom uk-margin-top">8. Dokumentation</h2>

<div class="field_container uk-width-1@s">
  <p class="field_container_headline">8.1</p>
  <div class="uk-margin-bottom">
    <label>Störungs-Hotline Aufkleber vorhanden?</label>
    <div class="select_container">
      <input v-model="form.nr8_1" class="uk-radio" type="radio" name="nr8_1" value="Nein"
        required><label>NEIN</label>

      <input v-model="form.nr8_1" class="uk-radio" type="radio" name="nr8_1" value="Ja"><label>JA</label>
    </div>
  </div>
  <div>
    <p>Wann: Frühjahr/Herbst</p>
  </div>
  <div v-if="this.form.nr8_1 == 'Nein'" class="uk-margin-bottom">
    <label>Anmerkungen</label>
    <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
      name="nr8_1_anmerkung" v-model="form.nr8_1_anmerkung" required></textarea>
  </div>
</div>

<div class="field_container uk-width-1@s">
  <p class="field_container_headline">8.2</p>
  <div class="uk-margin-bottom">
    <label>Schaltplan und Logbuch vorhanden?</label>
    <div class="select_container">
      <input v-model="form.nr8_2" class="uk-radio" type="radio" name="nr8_2" value="Nein"
        required><label>NEIN</label>

      <input v-model="form.nr8_2" class="uk-radio" type="radio" name="nr8_2" value="Ja"><label>JA</label>
    </div>
  </div>
  <div>
    <p>Wann: Frühjahr/Herbst</p>
  </div>
  <div v-if="this.form.nr8_2 == 'Nein'" class="uk-margin-bottom">
    <label>Anmerkungen</label>
    <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
      name="nr8_2_anmerkung" v-model="form.nr8_2_anmerkung" required></textarea>
  </div>
</div>

<div class="field_container uk-width-1@s">
  <p class="field_container_headline">8.3</p>
  <div class="uk-margin-bottom">
    <label>Prüfplakette am Hauser-Schaltkasten mit Oranger Blitzleuchte anbringen.</label>
    <div class="select_container">
      <input v-model="form.nr8_3" class="uk-radio" type="radio" name="nr8_3" value="Nein"
        required><label>NEIN</label>

      <input v-model="form.nr8_3" class="uk-radio" type="radio" name="nr8_3" value="Ja"><label>JA</label>
    </div>
  </div>
  <div>
    <p>Wann: Frühjahr/Herbst</p>
  </div>
  <div v-if="this.form.nr8_3 == 'Nein'" class="uk-margin-bottom">
    <label>Anmerkungen</label>
    <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
      name="nr8_3_anmerkung" v-model="form.nr8_3_anmerkung" required></textarea>
  </div>
</div>


<h2 class="uk-margin-bottom uk-margin-top">9. Tauwasserpumpen</h2>

<div class="field_container uk-width-1@s">
  <p class="field_container_headline">9.1</p>
  <div class="uk-margin-bottom">
    <label>Tauwasserpumpen prüfen und reingen</label>
    <div class="select_container">
      <input v-model="form.nr9_1" class="uk-radio" type="radio" name="nr9_1" value="Nein"
        required><label>NEIN</label>

      <input v-model="form.nr9_1" class="uk-radio" type="radio" name="nr9_1" value="Ja"><label>JA</label>
    </div>
  </div>
  <div>
    <p>Wann: Frühjahr/Herbst</p>
  </div>
  <div v-if="this.form.nr9_1 == 'Nein'" class="uk-margin-bottom">
    <label>Anmerkungen</label>
    <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
      name="nr9_1_anmerkung" v-model="form.nr9_1_anmerkung" required></textarea>
  </div>
</div>

              <div class="field_container uk-width-1@s">
                <div>
                  <label>Bemerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Bemerkungen"
                    name="bemerkungen" v-model="form.bemerkungen"></textarea>
                </div>
              </div>

              <div class="note uk-margin-top">Hinweis:<br>Wird bei Inspektions- und Wartungsarbeiten im Rahmen eines
                Instandhaltungsvertrages festgestellt, dass durch Nachbessern und Nachstellen der Sollzustand und die
                Funktion nicht wiederhergestellt werden können, so werden die betreffenden Betriebsmittel und -stoffe
                sowie
                Geräte oder Teile davon ergänzt bzw. ausgewechselt.
              </div>

            </div>

            <div class="uk-grid uk-grid-stack no-margin-bot">

              <div class="uk-width-1-2@m uk-margin-bottom">
                <input class="uk-input" name="marktleiter_name" type="text"
                  placeholder="Name Marktleiter (BLOCKBUCHSTABEN)" aria-label="Name Marktleiter (BLOCKBUCHSTABEN)"
                  v-model="this.form.marktleiter_name" required>
              </div>

              <div class="uk-width-1-2@m uk-margin-bottom">
                <input class="uk-input" name="servicetechniker_name" type="text"
                  placeholder="Name Service Techniker (BLOCKBUCHSTABEN)"
                  aria-label="Name Service Techniker (BLOCKBUCHSTABEN)" v-model="this.form.servicetechniker_name"
                  required>
              </div>

            </div>

            <div class="uk-grid uk-grid-stack no-margin-top">
              <div class="uk-width-1-2@m box_highlighted_darkgrey">
                <p>Bitte alle Felder, die nicht in der Herbstwartung erforderlich sind mit einer 0 befüllen</p>
              </div>
            </div>

         

            <div class="form_buttons">
              <button class="uk-button uk-button-primary uk-button-small submit-btn">Bearbeitung abschliessen</button>
            </div>

            <div v-if="this.showHint == true" class="submit-check-hint">
              <p>Überprüfen Sie bitte noch mal alle Ihre Daten auf korrekte Eingabe. Eine nachträgliche Bearbeitung ist
                nur vom Büro möglich.</p>
              <button @click="backToForm()">Zurück zur Bearbeitung</button>
              <button @click="sendFormFinal()">Formular absenden</button>
            </div>

        



          </div>



        </fieldset>
      </form>

    </div>

  </div>

 <!-- 1 -->
 <div v-if="this.mainFormActive != true">
      <div class="form_success_screen uk-grid">
        <div class="uk-width-1-1@m">
          <h3 class="status">{{ this.status }}</h3>
          <div class="centered button_container">
            <button @click="backToOverview">Zurück zur Formularauswahl</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 1 -->
</template>
<script>

import axios from 'axios'
import { reactive } from 'vue'

export default {
  name: 'FormPruefprotokoll',

  setup() {
    // make users variable reactive with the ref() function


    /*
    const form = reactive(
      
        { 
          form_title: "LIDL SPI CONNECT",
          filiale: "Test-Filiale",
          datum: null,
          userid: 1,

          tnummer: "001",

          nr1_1: "Nein",
          nr1_2: "Nein",
          nr1_3: "Nein",
          nr1_4: "Nein",

          nr2_1: "Nein",
          nr2_2: "Nein",
          nr2_3: "Nein",
          nr2_4: "Nein",
          nr2_5: "........° C",
          nr2_6a: "Nein",
          nr2_6b: "Nein",

          nr3_1: "Nein",
          nr3_2: "Nein",
          nr3_3: "test",

          nr4_1: "Nein",
          nr4_2: "Nein",
          nr4_3: "Nein",
          nr4_4: "Nein",
          nr4_5: "Nein",
          nr4_6: "Nein",
          nr4_7: "Nein",
          nr4_8: "Nein",
          nr4_9: "Nein",
          nr4_10: "Nein",

          nr5_1: "Nein",
          nr5_2: "Nein",
          nr5_3: "Nein",
          nr5_4: "Nein",

          nr6_1: "Nein",
          nr6_2: "Nein",
          nr6_3: "Nein",
          nr6_4: "Nein",
          nr6_5: "Nein",
          nr6_6: "Nein",
          nr6_7: "Nein",

          nr7_1: "Nein",
          nr7_2: "Nein",
          nr7_3: "Nein",
          nr7_4: "Nein",
          nr7_5: "Nein",

          nr8_1: "Nein",
          nr8_2: "Nein",
          nr8_3: "Nein",

          nr9_1: "Nein",

          nr1_1_anmerkung: "nr1_1_anmerkung",
          nr1_2_anmerkung: "nr1_2_anmerkung",
          nr1_3_anmerkung: "nr1_3_anmerkung",
          nr1_4_anmerkung: "nr1_4_anmerkung",

          nr2_1_anmerkung: "nr_2_1_anmerkung",
          nr2_2_anmerkung: "nr_2_2_anmerkung",
          nr2_3_anmerkung: "nr_2_3_anmerkung",
          nr2_4_anmerkung: "nr_2_4_anmerkung",
          nr2_5_anmerkung: "nr_2_5_anmerkung",
          nr2_6_anmerkung: "nr_2_6_anmerkung",
          nr2_6a_anmerkung: "nr_2_6_anmerkung",
          nr2_6b_anmerkung: "nr_2_6_anmerkung",

          nr3_1_anmerkung: "nr_3_1_anmerkung",
          nr3_2_anmerkung: "nr_3_2_anmerkung",
          nr3_3_anmerkung: "nr_3_3_anmerkung",

          nr4_1_anmerkung: "nr_4_1_anmerkung",
          nr4_2_anmerkung: "nr_4_2_anmerkung",
          nr4_3_anmerkung: "nr_4_3_anmerkung",
          nr4_4_anmerkung: "nr_4_4_anmerkung",
          nr4_5_anmerkung: "nr_4_5_anmerkung",
          nr4_6_anmerkung: "nr_4_6_anmerkung",
          nr4_7_anmerkung: "nr_4_7_anmerkung",
          nr4_8_anmerkung: "nr_4_8_anmerkung",
          nr4_9_anmerkung: "nr_4_9_anmerkung",
          nr4_10_anmerkung: "nr_4_10_anmerkung",

          nr5_1_anmerkung: "nr_5_1_anmerkung",
          nr5_2_anmerkung: "nr_5_2_anmerkung",
          nr5_3_anmerkung: "nr_5_3_anmerkung",
          nr5_4_anmerkung: "nr_5_4_anmerkung",

          nr6_1_anmerkung: "nr_6_1_anmerkung",
          nr6_2_anmerkung: "nr_6_2_anmerkung",
          nr6_3_anmerkung: "nr_6_3_anmerkung",
          nr6_4_anmerkung: "nr_6_4_anmerkung",
          nr6_5_anmerkung: "nr_6_5_anmerkung",
          nr6_6_anmerkung: "nr_6_6_anmerkung",
          nr6_7_anmerkung: "nr_6_7_anmerkung",

          nr7_1_anmerkung: "nr_7_1_anmerkung",
          nr7_2_anmerkung: "nr_7_2_anmerkung",
          nr7_3_anmerkung: "nr_7_3_anmerkung",
          nr7_4_anmerkung: "nr_7_4_anmerkung",
          nr7_5_anmerkung: "nr_7_5_anmerkung",

          nr8_1_anmerkung: "nr_8_1_anmerkung",
          nr8_2_anmerkung: "nr_8_2_anmerkung",
          nr8_3_anmerkung: "nr_8_3_anmerkung",

          nr9_1_anmerkung: "nr_9_1_anmerkung",
                       
          marktleiter_name: "Markleiter-Name",
          servicetechniker_name: "Servicetechniker-Name",

          temperaturen: [{
            "temperatur": "0",
          }],


          bemerkungen: "Test Bemerkung",
          
          


        }         
      );
      */




   
    const form = reactive(

      {
        form_title: "LIDL SPI CONNECT",
        filiale: "",
        datum: null,

        tnummer: "",

        nr1_1: "",
        nr1_2: "",
        nr1_3: "",
        nr1_4: "",

        nr2_1: "",
        nr2_2: "",
        nr2_3: "",
        nr2_4: "",
        nr2_5: "........° C",
        nr2_6a: "",
        nr2_6b: "",

        nr3_1: "",
        nr3_2: "",
        nr3_3: "",

        nr4_1: "",
        nr4_2: "",
        nr4_3: "",
        nr4_4: "",
        nr4_5: "",
        nr4_6: "",
        nr4_7: "",
        nr4_8: "",
        nr4_9: "",
        nr4_10: "",

        nr5_1: "",
        nr5_2: "",
        nr5_3: "",
        nr5_4: "",

        nr6_1: "",
        nr6_2: "",
        nr6_2a: "",
        nr6_2b: "",

        nr6_3: "",
        nr6_4: "",
        nr6_5: "",
        nr6_6: "",
        nr6_7: "",

        nr7_1: "",
        nr7_2: "",
        nr7_3: "",
        nr7_4: "",
        nr7_5: "",

        nr8_1: "",
        nr8_2: "",
        nr8_3: "",

        nr9_1: "",

        nr1_1_anmerkung: "",
        nr1_2_anmerkung: "",
        nr1_3_anmerkung: "",
        nr1_4_anmerkung: "",

        nr2_1_anmerkung: "",
        nr2_2_anmerkung: "",
        nr2_3_anmerkung: "",
        nr2_4_anmerkung: "",
        nr2_5_anmerkung: "",
        nr2_6_anmerkung: "",

        nr3_1_anmerkung: "",
        nr3_2_anmerkung: "",
        nr3_3_anmerkung: "",

        nr4_1_anmerkung: "",
        nr4_2_anmerkung: "",
        nr4_3_anmerkung: "",
        nr4_4_anmerkung: "",
        nr4_5_anmerkung: "",
        nr4_6_anmerkung: "",
        nr4_7_anmerkung: "",
        nr4_8_anmerkung: "",
        nr4_9_anmerkung: "",
        nr4_10_anmerkung: "",

        nr5_1_anmerkung: "",
        nr5_2_anmerkung: "",
        nr5_3_anmerkung: "",
        nr5_4_anmerkung: "",

        nr6_1_anmerkung: "",
        nr6_2_anmerkung: "",
        nr6_2a_anmerkung: "",
        nr6_2b_anmerkung: "",
        nr6_3_anmerkung: "",
        nr6_4_anmerkung: "",
        nr6_5_anmerkung: "",
        nr6_6_anmerkung: "",
        nr6_7_anmerkung: "",

        nr7_1_anmerkung: "",
        nr7_2_anmerkung: "",
        nr7_3_anmerkung: "",
        nr7_4_anmerkung: "",
        nr7_5_anmerkung: "",

        nr8_1_anmerkung: "",
        nr8_2_anmerkung: "",
        nr8_3_anmerkung: "",

        nr9_1_anmerkung: "",

        marktleiter_name: "",
        servicetechniker_name: "",

        temperaturen: [{
          "temperatur": "0",
        }],


        bemerkungen: ""
      }
    );

    
    return { form }
  },

  data: function () {
    return {
      mainFormActive: true,
      overviewPage: "https://hands4you.codepreview.de/formulare",
      status: "Danke Ihr Auftrag wurde gespeichert.",
      currentID: null,
      currentUser: null,
      showHint: false,

    }
  },

  mounted() {
    this.form.datum = this.getCurrentDate();

    console.log("TEST: " + this.form.datum);

    if (typeof userid !== 'undefined') {
      this.form.userid = userid;
    }

    if (typeof document.getElementById('meta-app-user-lastname') !== undefined && document.getElementById('meta-app-user-lastname') != null) {
      this.currentUser = {
        id: document.getElementById('meta-app-user-lastname').content,
        lastname: document.getElementById('meta-app-user-lastname').content,
        firstname: document.getElementById('meta-app-user-firstname').content
      }
    }

  },

  methods: {
    sendForm: async function () {
      console.log('CHECK NOW');
      this.showHint = true;
    },

    temperaturen_addListItem(event, dataObj) {
      event.preventDefault();

      console.log("ADD");

      dataObj.push(
        {
          "temperatur": "0",
        }
      )
    },

    temperaturen_removeListItem(event, dataObj, item, index) {
      event.preventDefault();
      dataObj.splice(index, 1)
    },

    getUserID: async function () {
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/form_arbeitsbericht.php';
      try {
        let payload = JSON.stringify(this.form);
        const { data } = await axios.post(url, payload);
      } catch (e) {
        console.log(e);
      }
    },

    createPDF: async function () {
      console.log("createPDF");
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/generatePDF.php';
      let myData =
      {
        id: this.currentID,
        formular_detail_ansicht_url: "https://hands4you.codepreview.de/formulare/pruefprotokoll-spi-connect/detailansicht/"
      }
      let payload = JSON.stringify(myData);
      try {
        // const {returnValue} = await axios.post(url, payload);
        // console.log(returnValue);

        axios.post(url,
          payload,
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/pdf'
            }
          })
          .then((response) => {

            // Er erstellt einen Link zu dem PDF und führt dann den Klick auf den Link aus, abgefahren

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let myFileName = "";

            if (this.currentUser != null) {
              myFileName = "pruefprotokoll_spiconnect_" + this.currentUser.lastname + "_" + this.currentID + ".pdf";
            } else {
              myFileName = "pruefprotokoll_spiconnect_" + this.currentID + ".pdf";
            }


            link.setAttribute('download', myFileName); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => console.log(error));
        this.status = "Die PDF wurde generiert.";

      } catch (e) {
        console.log(e);
      }
    },
   
    backToOverview: function () {
      window.open(this.overviewPage, "_self")
    },

    getCurrentDate: function () {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Monate sind 0-basiert
      const year = today.getFullYear();

      return `${day}.${month}.${year}`;
    },


    createFormData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },

    backToForm: function () {
      this.showHint = false;
    },
    sendFormFinal: async function () {
      console.log("sendFormFinal")
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/form_pruefprotokoll_spi.php';

      try {
        let payload = JSON.stringify(this.form);
        const { data } = await axios.post(url, payload);
        console.log(data);

        // The script returns the id of the just created dataset
        this.currentID = data;
        console.log("NEW ID: " + this.currentID);

        window.scrollTo(0, 0);
        this.mainFormActive = false;
      } catch (e) {
        console.log(e);
      }
    }


  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$white: #FFF;

.form_title {
  color: $white;
}
</style>
