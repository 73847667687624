<template>
  <div class="form-pruefprotokoll">
    <div v-if="this.mainFormActive">

      <form @submit.prevent="sendForm">


        <fieldset class="uk-fieldset active">
          <div class="inner">
            <div class="uk-grid uk-grid-stack">
              <div class="uk-width-1-3@m">
                <input class="uk-input uk-margin-bottom" name="filiale" type="text" placeholder="Filiale"
                  aria-label="Filiale" v-model="this.form.filiale" required>
              </div>

              <div class="uk-width-1-3@m">
                <input class="uk-input uk-margin-bottom" name="datum" type="text" placeholder="TT.MM.YYYY"
                  aria-label="Datum" v-model="this.form.datum" required>
              </div>

              <div class="uk-width-1-3@m">
                <input class="uk-input uk-margin-bottom" name="tnummer" type="text" placeholder="T-Nummer"
                  aria-label="T-Nummer" v-model="this.form.tnummer" required>
              </div>

            </div>

            <div class="uk-grid uk-grid-stack">

              <h2 class="uk-margin-bottom">1. Verdichter</h2>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">1.1</p>
                <div class="uk-margin-bottom">
                  <label>Auf Laufgeräusche prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr1_1" class="uk-radio" type="radio" name="nr1_1" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr1_1" class="uk-radio" type="radio" name="nr1_1" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div v-if="this.form.nr1_1 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr1_1_anmerkung" v-model="form.nr1_1_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">1.2</p>
                <div class="uk-margin-bottom">
                  <label>Stromaufnahme prüfen</label>
                  <div class="select_container">
                    <input v-model="form.nr1_2" class="uk-radio" type="radio" name="nr1_2" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr1_2" class="uk-radio" type="radio" name="nr1_2" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr1_2 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr1_2_anmerkung" v-model="form.nr1_2_anmerkung"></textarea>
                </div>

              </div>

              <h2 class="uk-margin-bottom uk-margin-top">2. Kühlmöbel</h2>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.1</p>
                <div class="uk-margin-bottom">
                  <label>Ausschalten und gegen Wiedereinschalten sichern.</label>
                  <div class="select_container">
                    <input v-model="form.nr2_1" class="uk-radio" type="radio" name="nr2_1" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_1" class="uk-radio" type="radio" name="nr2_1" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr2_1 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_1_anmerkung" v-model="form.nr2_1_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.2</p>
                <div class="uk-margin-bottom">
                  <label>Alle elektrischen Klemm- und Steckanschlüsse, Leitungsverbindungen auf festen Sitz prüfen</label>
                  <div class="select_container">
                    <input v-model="form.nr2_2" class="uk-radio" type="radio" name="nr2_2" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_2" class="uk-radio" type="radio" name="nr2_2" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr2_2 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_2_anmerkung" v-model="form.nr2_2_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.3</p>
                <div class="uk-margin-bottom">
                  <label>Äußerlich auf Verschmutzung, Beschädigung und Korrosion prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr2_3" class="uk-radio" type="radio" name="nr2_3" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_3" class="uk-radio" type="radio" name="nr2_3" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr2_3 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_3_anmerkung" v-model="form.nr2_3_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.4</p>
                <div class="uk-margin-bottom">
                  <label>Luftleitwände und Waben auf Verschmutzung prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr2_4" class="uk-radio" type="radio" name="nr2_4" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_4" class="uk-radio" type="radio" name="nr2_4" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr2_4 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_4_anmerkung" v-model="form.nr2_4_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.5</p>

                <div class="uk-margin-bottom">
                  <label>Türen auf Funktion prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr2_5" class="uk-radio" type="radio" name="nr2_5" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_5" class="uk-radio" type="radio" name="nr2_5" value="Ja"><label>JA</label>
                  </div>
                </div>

                <div v-if="this.form.nr2_5 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_5_anmerkung" v-model="form.nr2_5_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.6</p>
                <div class="uk-margin-bottom">
                  <label>Dokumentation überprüfen:<br>Schaltplan vorhanden<br>Sicherheitsaufkleber vorhanden</label>
                  <div class="select_container">
                    <input v-model="form.nr2_6" class="uk-radio" type="radio" name="nr2_6" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_6" class="uk-radio" type="radio" name="nr2_6" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr2_6 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_6_anmerkung" v-model="form.nr2_6_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.7</p>
                <div class="uk-margin-bottom">
                  <label>Verdunstungstasse, Tropfwanne und Tauwasserablaufschlauch auf Verschmutzung und Beschädigung
                    prüfen und
                    gegebenenfalls reinigen</label>
                  <div class="select_container">
                    <input v-model="form.nr2_7" class="uk-radio" type="radio" name="nr2_7" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_7" class="uk-radio" type="radio" name="nr2_7" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr2_7 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_7_anmerkung" v-model="form.nr2_7_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.8</p>
                <div class="uk-margin-bottom">
                  <label>Anlage wieder in Betrieb nehmen</label>
                  <div class="select_container">
                    <input v-model="form.nr2_8" class="uk-radio" type="radio" name="nr2_8" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_8" class="uk-radio" type="radio" name="nr2_8" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr2_8 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr2_8_anmerkung" v-model="form.nr2_8_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.9</p>
                <div class="uk-margin-bottom">
                  <label>Tauwasserpumpe auf funktion prüfen</label>
                  <div class="select_container">
                    <input v-model="form.nr2_9" class="uk-radio" type="radio" name="nr2_9" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_9" class="uk-radio" type="radio" name="nr2_9" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr_2_9 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr_2_9_anmerkung" v-model="form.nr_2_9_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.10</p>
                <div class="uk-margin-bottom">
                  <label>Temperaturen der Kühlmöbel überprüfen & dokumentieren:</label>

                  <textarea class="uk-textarea" cols="100" rows="10" placeholder="Korpus 1 ............................... °C
Korpus 2 ............................... °C
Korpus 3 ............................... °C
Korpus 4 ............................... °C
Korpus 5 ............................... °C
Korpus 6 ............................... °C
Korpus 7 ............................... °C
Korpus 8 ............................... °C
Korpus 9 ............................... °C
Korpus 10 ............................ °C" aria-label="Beschreibung" name="nr_2_10" v-model="form.nr_2_10"></textarea>



                </div>

                <div v-if="this.form.nr_2_10 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr_2_10_anmerkung" v-model="form.nr_2_10_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.11</p>
                <div class="uk-margin-bottom">
                  <label>Beleuchtung auf Funktion prüfen</label>
                  <div class="select_container">
                    <input v-model="form.nr2_11" class="uk-radio" type="radio" name="nr2_11" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_11" class="uk-radio" type="radio" name="nr2_11" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr_2_11 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr_2_11_anmerkung" v-model="form.nr_2_11_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.12</p>
                <div class="uk-margin-bottom">
                  <label>Sichtprüfung auf Feuchtigkeit</label>
                  <div class="select_container">
                    <input v-model="form.nr2_12" class="uk-radio" type="radio" name="nr2_12" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_12" class="uk-radio" type="radio" name="nr2_12" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr_2_12 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr_2_12_anmerkung" v-model="form.nr_2_12_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.13</p>
                <div class="uk-margin-bottom">
                  <label>Thermometer auf richtige Funktion, korrekte Fühlerposition und Fühlerbefestigung prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr2_13" class="uk-radio" type="radio" name="nr2_13" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_13" class="uk-radio" type="radio" name="nr2_13" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr_2_13 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr_2_13_anmerkung" v-model="form.nr_2_13_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.14</p>
                <div class="uk-margin-bottom">
                  <label>Parameter der Kühlstellenregler prüfen: (nach letztgültiger Einstellliste)</label>
                  <div class="select_container">
                    <input v-model="form.nr2_14" class="uk-radio" type="radio" name="nr2_14" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_14" class="uk-radio" type="radio" name="nr2_14" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr_2_14 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr_2_14_anmerkung" v-model="form.nr_2_14_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.15</p>
                <div class="uk-margin-bottom">
                  <label>Verdampferventilatoren auf Funktion ( Umluft ) und Geräusche prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr2_15" class="uk-radio" type="radio" name="nr2_15" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_15" class="uk-radio" type="radio" name="nr2_15" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr_2_15 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr_2_15_anmerkung" v-model="form.nr_2_15_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.16</p>
                <div class="uk-margin-bottom">
                  <label>Messen der Abtauheizungen</label>
                  <div class="select_container">
                    <input v-model="form.nr2_16" class="uk-radio" type="radio" name="nr2_16" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_16" class="uk-radio" type="radio" name="nr2_16" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr_2_16 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr_2_16_anmerkung" v-model="form.nr_2_16_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.17</p>
                <div class="uk-margin-bottom">
                  <label>Sicherheitseinrichtungen prüfen</label>
                  <div class="select_container">
                    <input v-model="form.nr2_17" class="uk-radio" type="radio" name="nr2_17" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_17" class="uk-radio" type="radio" name="nr2_17" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr_2_17 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr_2_17_anmerkung" v-model="form.nr_2_17_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">2.18</p>
                <div class="uk-margin-bottom">
                  <label>Testen der Alarmfunktion gemeinsam mit Fernservicecenter (Dokumentation erforderlich)</label>
                  <div class="select_container">
                    <input v-model="form.nr2_18" class="uk-radio" type="radio" name="nr2_18" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr2_18" class="uk-radio" type="radio" name="nr2_18" value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="this.form.nr_2_18 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr_2_18_anmerkung" v-model="form.nr_2_18_anmerkung"></textarea>
                </div>
              </div>


              <h2 class="uk-margin-bottom uk-margin-top">3. Kondensator auf Kühlmöbel</h2>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">3.1</p>
                <div class="uk-margin-bottom">
                  <label>Äußerlich auf Verschmutzung, Beschädigung und Korrosion prüfen und Verflüssiger trocken
                    reinigen</label>
                  <div class="select_container">
                    <input v-model="form.nr3_1" class="uk-radio" type="radio" name="nr3_1" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr3_1" class="uk-radio" type="radio" name="nr3_1" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div v-if="this.form.nr3_1 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr3_1_anmerkung" v-model="form.nr3_1_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <p class="field_container_headline">3.2</p>
                <div class="uk-margin-bottom">
                  <label>Ventilatorfunktion und Ventilatorlager auf Geräuschbildung prüfen:</label>
                  <div class="select_container">
                    <input v-model="form.nr3_2" class="uk-radio" type="radio" name="nr3_2" value="Nein"
                      required><label>NEIN</label>
                    <input v-model="form.nr3_2" class="uk-radio" type="radio" name="nr3_2" value="Ja"><label>JA</label>
                  </div>
                </div>
                <div>
                  <p>Wann: Frühjahr</p>
                </div>
                <div v-if="this.form.nr3_2 == 'Nein'" class="uk-margin-bottom">
                  <label>Anmerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                    name="nr3_2_anmerkung" v-model="form.nr3_2_anmerkung"></textarea>
                </div>
              </div>

              <div class="field_container uk-width-1@s">
                <div>
                  <label>Bemerkungen</label>
                  <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Bemerkungen"
                    name="bemerkungen" v-model="form.bemerkungen"></textarea>
                </div>
              </div>

              <div class="note uk-margin-top">Hinweis:<br>Wird bei Inspektions- und Wartungsarbeiten im Rahmen eines
                Instandhaltungsvertrages festgestellt, dass durch Nachbessern und Nachstellen der Sollzustand und die
                Funktion nicht wiederhergestellt werden können, so werden die betreffenden Betriebsmittel und -stoffe
                sowie
                Geräte oder Teile davon ergänzt bzw. ausgewechselt.
              </div>

            </div>

            <div class="uk-grid uk-grid-stack no-margin-bot">

              <div class="uk-width-1-2@m uk-margin-bottom">
                <input class="uk-input" name="marktleiter_name" type="text"
                  placeholder="Name Marktleiter (BLOCKBUCHSTABEN)" aria-label="Name Marktleiter (BLOCKBUCHSTABEN)"
                  v-model="this.form.marktleiter_name" required>
              </div>

              <div class="uk-width-1-2@m uk-margin-bottom">
                <input class="uk-input" name="servicetechniker_name" type="text"
                  placeholder="Name Service Techniker (BLOCKBUCHSTABEN)"
                  aria-label="Name Service Techniker (BLOCKBUCHSTABEN)" v-model="this.form.servicetechniker_name"
                  required>
              </div>

            </div>

            <div class="uk-grid uk-grid-stack no-margin-top">
              <div class="uk-width-1-2@m box_highlighted_darkgrey">
                <p>Bitte alle Felder, die nicht in der Herbstwartung erforderlich sind mit einer 0 befüllen</p>
              </div>
            </div>



            <div class="form_buttons">
              <button class="uk-button uk-button-primary uk-button-small submit-btn">Bearbeitung abschliessen</button>
            </div>

            <div v-if="this.showHint == true" class="submit-check-hint">
              <p>Überprüfen Sie bitte noch mal alle Ihre Daten auf korrekte Eingabe. Eine nachträgliche Bearbeitung ist
                nur vom Büro möglich.</p>
              <button @click="backToForm()">Zurück zur Bearbeitung</button>
              <button @click="sendFormFinal()">Formular absenden</button>
            </div>





          </div>



        </fieldset>
      </form>

    </div>

  </div>

  <!-- 1 -->
  <div v-if="this.mainFormActive != true">
    <div class="form_success_screen uk-grid">
      <div class="uk-width-1-1@m">
        <h3 class="status">{{ this.status }}</h3>
        <div class="centered button_container">
          <button @click="backToOverview">Zurück zur Formularauswahl</button>
        </div>
      </div>
    </div>
  </div>
  <!-- 1 -->
</template>
<script>

import axios from 'axios'
import { reactive } from 'vue'

export default {
  name: 'FormWartungsprotokoll_RMG_CCP_Connect',

  setup() {
    // make users variable reactive with the ref() function



    const form = reactive(
      /* TEST
      { 
        form_title: "Wartungsprotokoll RMG-CCP Connect",
        filiale: "Test-Filiale",
        datum: null,
        userid: 1,

        tnummer: "001",

        nr1_1: "Nein",
        nr1_2: "Nein",
       
        nr2_1: "Nein",
        nr2_2: "Nein",
        nr2_3: "Nein",
        nr2_4: "Nein",
        nr2_5: "Nein",
        nr2_6: "Nein",
        nr2_7: "Nein",
        nr2_8: "Nein",
        nr2_9: "Nein",
        nr2_10: "Korpus 1: 22 Grad",
        nr2_11: "Nein",
        nr2_12: "Nein",
        nr2_13: "Nein",
        nr2_14: "Nein",
        nr2_15: "Nein",
        nr2_16: "Nein",
        nr2_17: "Nein",
        nr2_18: "Nein",
      
        nr3_1: "Nein",
        nr3_2: "Nein",
       
        nr1_1_anmerkung: "nr1_1_anmerkung",
        nr1_2_anmerkung: "nr1_2_anmerkung",

        nr2_1_anmerkung: "nr_2_1_anmerkung",
        nr2_2_anmerkung: "nr_2_2_anmerkung",
        nr2_3_anmerkung: "nr_2_3_anmerkung",
        nr2_4_anmerkung: "nr_2_4_anmerkung",
        nr2_5_anmerkung: "nr_2_5_anmerkung",
        nr2_6_anmerkung: "nr_2_6_anmerkung",
        nr2_7_anmerkung: "nr_2_7_anmerkung",
        nr2_8_anmerkung: "nr_2_8_anmerkung",
        nr2_9_anmerkung: "nr_2_9_anmerkung",
        nr2_10_anmerkung: "nr_2_10_anmerkung",
        nr2_11_anmerkung: "nr_2_11_anmerkung",
        nr2_12_anmerkung: "nr_2_12_anmerkung",
        nr2_13_anmerkung: "nr_2_13_anmerkung",
        nr2_14_anmerkung: "nr_2_14_anmerkung",
        nr2_15_anmerkung: "nr_2_15_anmerkung",
        nr2_16_anmerkung: "nr_2_16_anmerkung",
        nr2_17_anmerkung: "nr_2_17_anmerkung",
        nr2_18_anmerkung: "nr_2_18_anmerkung",
       
        nr3_1_anmerkung: "nr_3_1_anmerkung",
        nr3_2_anmerkung: "nr_3_2_anmerkung",
                               
        marktleiter_name: "Markleiter-Name",
        servicetechniker_name: "Servicetechniker-Name",
        bemerkungen: "Test Bemerkung",
      }  
      */



      {
        form_title: "Wartungsprotokoll RMG-CCP Connect",
        filiale: "",
        datum: null,
        userid: 1,

        tnummer: "001",

        nr1_1: "Nein",
        nr1_2: "Nein",

        nr2_1: "Nein",
        nr2_2: "Nein",
        nr2_3: "Nein",
        nr2_4: "Nein",
        nr2_5: "Nein",
        nr2_6: "Nein",
        nr2_7: "Nein",
        nr2_8: "Nein",
        nr2_9: "Nein",
        nr2_10: "",
        nr2_11: "Nein",
        nr2_12: "Nein",
        nr2_13: "Nein",
        nr2_14: "Nein",
        nr2_15: "Nein",
        nr2_16: "Nein",
        nr2_17: "Nein",
        nr2_18: "Nein",

        nr3_1: "Nein",
        nr3_2: "Nein",

        nr1_1_anmerkung: "",
        nr1_2_anmerkung: "",

        nr2_1_anmerkung: "",
        nr2_2_anmerkung: "",
        nr2_3_anmerkung: "",
        nr2_4_anmerkung: "",
        nr2_5_anmerkung: "",
        nr2_6_anmerkung: "",
        nr2_7_anmerkung: "",
        nr2_8_anmerkung: "",
        nr2_9_anmerkung: "",
        nr2_10_anmerkung: "",
        nr2_11_anmerkung: "",
        nr2_12_anmerkung: "",
        nr2_13_anmerkung: "",
        nr2_14_anmerkung: "",
        nr2_15_anmerkung: "",
        nr2_16_anmerkung: "",
        nr2_17_anmerkung: "",
        nr2_18_anmerkung: "",

        nr3_1_anmerkung: "",
        nr3_2_anmerkung: "",

        marktleiter_name: "",
        servicetechniker_name: "",
        bemerkungen: "",
      }

    );

    return { form }
  },

  data: function () {
    return {
      mainFormActive: true,
      overviewPage: "https://hands4you.codepreview.de/formulare",
      status: "Danke Ihr Auftrag wurde gespeichert.",
      currentID: null,
      currentUser: null,
      showHint: false,

    }
  },

  mounted() {
    this.form.datum = this.getCurrentDate();

    console.log("TEST: " + this.form.datum);

    if (typeof userid !== 'undefined') {
      this.form.userid = userid;
    }

    if (typeof document.getElementById('meta-app-user-lastname') !== undefined && document.getElementById('meta-app-user-lastname') != null) {
      this.currentUser = {
        id: document.getElementById('meta-app-user-lastname').content,
        lastname: document.getElementById('meta-app-user-lastname').content,
        firstname: document.getElementById('meta-app-user-firstname').content
      }
    }

  },

  methods: {
    sendForm: async function () {
      console.log('CHECK NOW');
      this.showHint = true;
    },

    temperaturen_addListItem(event, dataObj) {
      event.preventDefault();

      console.log("ADD");

      dataObj.push(
        {
          "temperatur": "0",
        }
      )
    },

    temperaturen_removeListItem(event, dataObj, item, index) {
      event.preventDefault();
      dataObj.splice(index, 1)
    },

    getUserID: async function () {
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/form_arbeitsbericht.php';
      try {
        let payload = JSON.stringify(this.form);
        const { data } = await axios.post(url, payload);
      } catch (e) {
        console.log(e);
      }
    },

    createPDF: async function () {
      console.log("createPDF");
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/generatePDF.php';
      let myData =
      {
        id: this.currentID,
        formular_detail_ansicht_url: "https://hands4you.codepreview.de/formulare/wartungsprotokoll-rmg-ccp-connect/detailansicht/"
      }
      let payload = JSON.stringify(myData);
      try {
        // const {returnValue} = await axios.post(url, payload);
        // console.log(returnValue);

        axios.post(url,
          payload,
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/pdf'
            }
          })
          .then((response) => {

            // Er erstellt einen Link zu dem PDF und führt dann den Klick auf den Link aus, abgefahren

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let myFileName = "";

            if (this.currentUser != null) {
              myFileName = "wartungsprotokoll_rmg_ccp_connect_" + this.currentUser.lastname + "_" + this.currentID + ".pdf";
            } else {
              myFileName = "wartungsprotokoll_rmg_ccp_connect_" + this.currentID + ".pdf";
            }


            link.setAttribute('download', myFileName); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => console.log(error));
        this.status = "Die PDF wurde generiert.";

      } catch (e) {
        console.log(e);
      }
    },
 
    backToOverview: function () {
      window.open(this.overviewPage, "_self")
    },

    getCurrentDate: function () {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Monate sind 0-basiert
      const year = today.getFullYear();

      return `${day}.${month}.${year}`;
    },


    createFormData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },

    backToForm: function () {
      this.showHint = false;
    },
    sendFormFinal: async function () {
      console.log("sendFormFinal")
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/form_wartungsprotokoll_rmg_ccp_connect.php';

      try {
        let payload = JSON.stringify(this.form);
        const { data } = await axios.post(url, payload);
        console.log(data);

        // The script returns the id of the just created dataset
        this.currentID = data;
        console.log("NEW ID: " + this.currentID);

        window.scrollTo(0, 0);
        this.mainFormActive = false;
      } catch (e) {
        console.log(e);
      }
    }


  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$white: #FFF;

.form_title {
  color: $white;
}
</style>
