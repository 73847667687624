<template>
  <div class="form-maengelliste">


    <form @submit.prevent="sendForm">
      <div v-if="this.mainFormActive">
        <fieldset class="uk-fieldset active">

          <div class="inner">
            <div class="uk-grid-small uk-grid">

              <div class="uk-width-auto@s uk-margin-bottom">
                <input class="uk-input" name="techniker" type="text" placeholder="Techniker" aria-label="Techniker"
                  v-model="this.form.techniker">
              </div>

              <div class="uk-width-auto@s uk-margin-bottom">
                <input class="uk-input" name="kunde" type="text" placeholder="Kunde" aria-label="Kunde"
                  v-model="this.form.kunde">
              </div>

              <div class="uk-width-auto@s uk-margin-bottom">
                <input class="uk-input" name="ort" type="text" placeholder="Ort" aria-label="Ort" v-model="this.form.ort">
              </div>

              <div class="uk-width-auto@s uk-margin-bottom">
                <input class="uk-input" name="strasse" type="text" placeholder="Straße" aria-label="Straße"
                  v-model="this.form.strasse">
              </div>
            </div>


            <div class="uk-grid-small uk-grid">
              <div>
                <h4 class="fieldset_headline">Sind Mängel vorhanden</h4>
                <label>Sind Mängel vorhanden? </label>
                <div class="select_container">
                  <input v-model="form.maengel_vorhanden" class="uk-radio" type="radio" name="maengel_vorhanden"
                    value="Nein"><label>NEIN</label>


                  <input v-model="form.maengel_vorhanden" class="uk-radio" type="radio" name="maengel_vorhanden"
                    value="Ja"><label>JA</label>
                </div>


              </div>
            </div>


            <div class="uk-grid-small uk-grid">

              <div class="datagrid no-padding">
                <div class="inner">

                  <div v-for="(item, index) in this.form.maengelliste" :key="item.index">

                    <div class="item">
                      <div class="inner">

                        <div class="uk-grid-small" uk-grid>

                          <div class="field_container uk-width-1-4@m">
                            <label>Pos.</label>
                            <input class="uk-input input-position" type="text" name="position" placeholder=""
                              aria-label="Position" v-model="item.position">
                          </div>

                          <div class="field_container uk-width-1-4@m">
                            <label>Seriennummer</label>
                            <input class="uk-input" type="text" name="seriennummer" aria-label="Seriennummer"
                              v-model="item.seriennummer">
                          </div>

                          <div class="field_container uk-width-1-4@m">
                            <label>KP-Nummer</label>
                            <input class="uk-input" type="text" name="kpnummer" aria-label="KP-Nummer"
                              v-model="item.kpnummer">
                          </div>

                          <div class="field_container uk-width-1-4@m">
                            <label>Typ</label>
                            <input class="uk-input" type="text" name="typ" aria-label="Typ" v-model="item.typ">
                          </div>

                        </div>

                        <div class="uk-grid-small" uk-grid>
                          <div class="field_container uk-width-1@m">
                            <label>Beschreibung des Fehlers</label>
                            <textarea class="uk-textarea" cols="100" rows="2" placeholder="" aria-label="Beschreibung"
                              name="beschreibung" v-model="item.beschreibung"></textarea>
                          </div>
                        </div>

                        <div class="uk-grid-small" uk-grid>
                          <div class="field_container uk-width-1@m">
                            <p class="no-margin-bot">Fotos</p>
                            <p class="tinynote">Die Anzahl der Fotos ist auf 4 Bilder beschränkt.</p>
                            <div class="file_uploader">
                              <Uploader :myindex=index :server=this.file_uploader_path @add="addMedia"
                                @remove="removeMedia" :max=4 :maxFilesize=15 :inputId="'mu-file-input-' + index" />
                            </div>
                          </div>
                        </div>

                        <div class="uk-grid-small" uk-grid>

                          <div class="field_container uk-width-1@m">
                            <label>Arbeiten durchgeführt mit Ticket ...</label>
                            <input class="uk-input" type="text" name="ticket" aria-label="Ticket" v-model="item.ticket">
                          </div>

                          <div class="uk-margin-bottom">
                            <label>Ersatzteil erforderlich</label>

                            <input v-model="item.ersatzteil_erforderlich" class="uk-radio" type="radio"
                              name="ersatzteil_erforderlich" value="Nein"><label>NEIN</label>


                            <input v-model="item.ersatzteil_erforderlich" class="uk-radio" type="radio"
                              name="ersatzteil_erforderlich" value="Ja"><label>JA</label>

                          </div>
                        </div>

                      </div>

                      <div class="grid_nav nav-container">
                        <button @click="maengelliste_addListItem(this.form.maengelliste)"
                          class="uk-button uk-button-secondary uk-button-small uk-margin-small-right uk-margin-bottom"><span
                            class="uk-icon" uk-icon="plus-circle"></span>Neuen Eintrag hinzufügen</button>
                        <button @click="maengelliste_removeListItem(this.form.maengelliste, item, index)"
                          class="uk-button uk-button-secondary uk-button-small uk-margin-bottom"><span class="uk-icon"
                            uk-icon="minus-circle"></span>Eintrag löschen</button>
                      </div>

                    </div>

                  </div>
                </div>

              </div>
            </div>


            <!-- 1 -->

            <div class="form_buttons">
              <button class="uk-button uk-button-primary uk-button-small submit-btn">Bearbeitung abschliessen</button>
            </div>

            <div v-if="this.showHint == true" class="submit-check-hint">
              <p>Überprüfen Sie bitte noch mal alle Ihre Daten auf korrekte Eingabe. Eine nachträgliche Bearbeitung ist
                nur vom Büro möglich.</p>
              <button @click="backToForm()">Zurück zur Bearbeitung</button>
              <button @click="sendFormFinal()">Formular absenden</button>
            </div>

            <!-- 1 -->

          </div>

        </fieldset>
      </div>

    </form>

  </div>

  <!-- 1 -->
  <div v-if="this.mainFormActive != true">
    <div class="form_success_screen uk-grid">
      <div class="uk-width-1-1@m">
        <h3 class="status">{{ this.status }}</h3>
        <div class="centered button_container">
          <button @click="backToOverview">Zurück zur Formularauswahl</button>
        </div>
      </div>
    </div>
  </div>
  <!-- 1 -->
</template>

<script>

import axios from 'axios'
import { reactive } from 'vue'

export default {
  name: 'FormPruefprotokoll',

  setup() {
    // make users variable reactive with the ref() function

    
    const form = reactive(

      {
        techniker: '',
        kunde: '',
        ort: '',
        userid: '',
        strasse: '',
        maengel_vorhanden: "",
        maengelliste: [{
          "position": "1",
          "seriennummer": "",
          "kpnummer": "",
          "typ": "",
          "ticket": "",
          "ersatzteil_erforderlich": "",
          "beschreibung": "",
          "datei_1": "",
          "datei_2": "",
          "datei_3": "",
          "datei_4": "",
        }],

      }

    );
  


    /*
    const form = reactive(
      
        { 
          techniker: 'Mein Techniker',
          kunde: 'Mein Kunde',
          ort: 'Mein Ort',
          userid: '',
          strasse: 'Meine Straße',
          maengel_vorhanden: "Ja",
          maengelliste: [{
            "position": "1",
            "seriennummer": "001",
            "kpnummer": "002",
            "typ": "MeinTyp",
            "ticket": "003",
            "ersatzteil_erforderlich": "Ja",
            "beschreibung": "Beschreibung des Fehlers",
            "datei_1": "",
            "datei_2": "",
            "datei_3": "",
            "datei_4": "",
          }],
         
        }
      
      );
    */

    return { form }
  },

  data: function () {
    return {
      mainFormActive: true,
      overviewPage: "https://hands4you.codepreview.de/formulare",
      status: "Danke Ihr Auftrag wurde gespeichert.",
      currentID: null,
      currentUser: null,
      currentUploaderIndex: "",
      media: [],
      formID: Date.now(),
      file_uploader_path: "",
      showHint: false,
      upload_formular: 'maengelliste'
    }
  },

  mounted() {

    this.file_uploader_path = 'https://hands4you.codepreview.de/files/hands4you/php/file_upload.php?id=' + this.formID +"&formular=" + this.upload_formular;

    if (typeof userid !== 'undefined') {
      this.form.userid = userid;
    }



    if (typeof user_lastname !== 'undefined' && typeof user_firstname !== 'undefined') {
      this.form.techniker = user_firstname + " " + user_lastname;
    }

    /*
    if(typeof document.getElementById('meta-app-user-lastname') !== undefined && document.getElementById('meta-app-user-lastname') != null) {
        this.currentUser = {
                id: document.getElementById('meta-app-user-lastname').content,
                lastname: document.getElementById('meta-app-user-lastname').content,
                firstname: document.getElementById('meta-app-user-firstname').content
        }
      }

      if(typeof this.currentUser) {
        if( this.currentUser.lastname != "") {
          this.data.techniker = this.currentUser.firstname+" "+this.currentUser.lastname;
        }
      }
      */

  },

  methods: {

    sendForm: async function () {
      console.log('CHECK NOW');
      this.showHint = true;
    },


    sendFormFinal: async function () {
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/form_maengelliste.php';

      try {
        let payload = JSON.stringify(this.form);

        console.log(payload);

        const { data } = await axios.post(url, payload);
        console.log(data);

        // The script returns the id of the just created dataset
        this.currentID = data;
        console.log("NEW ID: " + this.currentID);

        window.scrollTo(0, 0);
        this.mainFormActive = false;

      } catch (e) {
        console.log(e);
      }
    },

    backToForm: function () {
      this.showHint = false;
    },


    maengelliste_removeListItem(dataObj, item, index) {
      dataObj.splice(index, 1)
    },

    addMedia(myindex, addedImage, addedMedia) {
      /*
      param1 : The image that was added.
      param2 : The list of the added Images.
      */
      this.currentUploaderIndex = myindex;
      let str = JSON.stringify(addedMedia);
      this.media.added = addedMedia

      //console.log("DATA: "+str)


      for (var i = 0; i < this.media.added.length; i++) {

        var index = i + 1;
        // Dynamischer Name der Dateieigenschaft mit dem aktuellen Index
        var dateiEigenschaftName = "datei_" + index;

        this.form.maengelliste[this.currentUploaderIndex][dateiEigenschaftName] = this.media.added[i]['name'];
      }

      //const jsonString = JSON.stringify(this.form, null, 2);
      //console.log("DATA: "+jsonString)

    },

    removeMedia(myindex, removedImage) {
      this.currentUploaderIndex = myindex;

      let filePath = removedImage.name;

      //console.log("LEN: "+this.form.maengelliste.length);

      for (var i = 0; i < this.form.maengelliste.length; i++) {

        for (var a = 1; a < 5; a++) {
          if (this.form.maengelliste[i]['datei_' + a] == removedImage.name) {
            this.form.maengelliste[i]['datei_' + a] = "";
          }

        }
      }

      const jsonString = JSON.stringify(this.form, null, 2);

      let url = 'https://hands4you.codepreview.de/files/hands4you/php/remove_image.php';

      // Create a new FormData object
      const formData = new FormData();

      // Append the file to the FormData object
      formData.append('myFile', removedImage.name);

      // Make a POST request using Axios
      axios.post(url, formData)
        .then(response => {
          // Handle the response from the PHP script
          console.log(response.data);
        })
        .catch(error => {
          // Handle any errors
          console.error(error);
        });
    },

    createPDF: async function () {
      console.log("createPDF");
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/generatePDF.php';
      let myData =
      {
        id: this.currentID,
      }
      let payload = JSON.stringify(myData);
      try {
        // const {returnValue} = await axios.post(url, payload);
        // console.log(returnValue);

        axios.post(url,
          payload,
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/pdf'
            }
          })
          .then((response) => {

            // Er erstellt einen Link zu dem PDF und führt dann den Klick auf den Link aus, abgefahren

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let myFileName = "";

            if (this.currentUser != null) {
              myFileName = "arbeitsbericht_" + this.currentUser.lastname + "_" + this.currentID + ".pdf";
            } else {
              myFileName = "arbeitsbericht_" + this.currentID + ".pdf";
            }


            link.setAttribute('download', myFileName); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => console.log(error));
        this.status = "Die PDF wurde generiert.";

      } catch (e) {
        console.log(e);
      }
    },

    sendDataViaMail: async function () {
      console.log("sendDataViaMail");

      let url = 'https://hands4you.codepreview.de/files/hands4you/php/sendDataToMail.php';

      try {
        let payload = JSON.stringify(this.form);
        const { data } = await axios.post(url, payload);
        this.status = "Ihre E-Mail wurde verschickt";
      } catch (e) {
        console.log(e);
      }
    },

    // Generate a random directory name
    generateDirectoryName: function () {
      // Get the current timestamp
      const timestamp = Date.now();

      // Generate a random number between 0 and 9999
      const randomNumber = Math.floor(Math.random() * 10000);

      // Combine the timestamp and random number to create the directory name
      const directoryName = `${timestamp}_${randomNumber}`;

      return directoryName;
    },

    backToOverview: function () {
      window.open(this.overviewPage, "_self")
    },

    maengelliste_addListItem(dataObj) {
      let currentLen = dataObj.length;
      dataObj.push(
        {
          "position": currentLen + 1,
          "seriennummer": "",
          "kpnummer": "",
          "typ": "",
          "ticket": "",
          "ersatzteil_erforderlich": "",
          "beschreibung": "",
          "datei_1": "",
          "datei_2": "",
          "datei_3": "",
          "datei_4": "",
        }
      )
    },

    createFormData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
