<template>
  <div class="form-arbeitsberichte">


    <form @submit.prevent="sendForm">

      <div v-if="this.mainFormActive">
        <div class="uk-grid">

          <!-- Seite 1-->


          <div class="inner">

            <fieldset class="uk-fieldset active">
              <div class="inner">
                <div class="uk-margin-bottom">
                  <h4 class="fieldset_headline">Typ auswählen</h4>
                </div>

                <div class="m-bottom-75">

                  <div class="select_container">
                    <input v-model="form.typ" class="uk-radio" type="radio" name="Montage"
                      value="Montage"><label>Montage</label>
                  </div>

                  <div class="select_container">
                    <input v-model="form.typ" class="uk-radio" type="radio" name="Reparatur"
                      value="Reparatur"><label>Reparatur</label>
                  </div>

                  <div class="select_container">
                    <input v-model="form.typ" class="uk-radio" type="radio" name="Wartung"
                      value="Wartung"><label>Wartung</label>
                  </div>
                  <div class="select_container">
                    <input v-model="form.typ" class="uk-radio" type="radio" name="Reinigung"
                      value="Reinigung"><label>Reinigung</label>
                  </div>
                  <div class="select_container">
                    <input v-model="form.typ" class="uk-radio" type="radio" name="Zusatzarbeit"
                      value="Zusatzarbeit"><label>Zusatzarbeit</label>
                  </div>
                </div>
              </div>

            </fieldset>

            <fieldset class="uk-fieldset active">

              <div class="inner">

                <div class="uk-grid-small uk-grid">

                  <div class="uk-margin-bottom uk-width-auto@s">
                    <input class="uk-input" name="datum" type="text" placeholder="Datum" aria-label="Datum"
                      v-model="this.form.datum" required>
                  </div>

                  <div class="uk-margin-bottom uk-width-auto@s">
                    <input class="uk-input" name="serviceticketnr" type="text" placeholder="Service-Ticket-Nr."
                      aria-label="Service-Ticket-Nr." v-model="this.form.serviceticketnr" required>
                  </div>

                  <div class="uk-margin-bottom uk-width-auto@s">
                    <input class="uk-input" name="mobile_c_nr" type="text" placeholder="MobileC-Nr"
                      aria-label="MobileC-Nr" v-model="this.form.mobile_c_nr" required>
                  </div>

                </div>
              </div>

            </fieldset>

            <fieldset class="uk-fieldset active">

              <div class="inner">

                <div class="fieldset_section">

                  <div class="uk-margin-bottom">
                    <h4 class="fieldset_headline">Kundeninformation</h4>
                  </div>


                  <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-1-3@m">
                      <input class="uk-input" type="text" name="tochtergesellschaft" placeholder="Gesellschaft"
                        aria-label="Gesellschaft" v-model="this.form.tochtergesellschaft">
                    </div>
                  </div>

                  <div class="uk-grid-small" uk-grid>
                    <div class="uk-margin-bottom uk-width-1-3@m">
                      <textarea class="uk-textarea" cols="100" rows="5" placeholder="Adresse" aria-label="Adresse"
                        name="adresse" v-model="this.form.adresse"></textarea>
                    </div>
                  </div>

                </div>

                <div class="fieldset_section">

                  <div class="uk-margin-bottom">
                    <h4 class="fieldset_headline">Servicepartner Information</h4>
                  </div>

                  <div class="uk-grid-small uk-grid">
                    <div class=" uk-width-1-3@m">
                      <input class="uk-input" type="text" name="name_sp" placeholder="Name (ausführender SP)"
                        aria-label="name_sp" v-model="this.form.name_sp">
                    </div>
                  </div>
                  <div class="uk-grid-small uk-grid">
                    <div class="uk-margin-bottom uk-width-1-3@m">
                      <input class="uk-input" type="text" name="rueckmeldenr" placeholder="Rückmeldenr."
                        aria-label="rueckmeldenr" v-model="this.form.rueckmeldenr">
                    </div>
                  </div>



                </div>

                <div class="fieldset_section">

                  <div class="uk-margin-bottom">
                    <h4 class="fieldset_headline">Anmerkungen</h4>
                  </div>

                  <div class="uk-grid-small uk-grid">
                    <div class="uk-margin-bottom uk-width-1-3@m">
                      <textarea class="uk-textarea" cols="100" rows="5" placeholder="Anmerkungen" name="anmerkungen"
                        aria-label="Anmerkungen" v-model="this.form.anmerkungen"></textarea>
                    </div>
                  </div>



                  <div class="note">
                    <p>Wir weisen darauf hin, dass die <strong>genaue</strong> Ursache des Defekts/des
                      Serviceeinsatz-Grundes anzuführen ist.</p>
                    <h5></h5>
                    <ul>
                      <li>Beschädigung durch Personal-/Anfahrschaden</li>
                      <li>Vereisung aufgrund von zu lang offen gelassener Tür</li>
                      <li>Wasseraustritt aufgrund von Verschmutzung (i.e. Etiketten im Abfluss, ...)</li>
                    </ul>
                  </div>

                </div>

              </div>

            </fieldset>

            <fieldset class="uk-fieldset active">

              <div class="inner">

                <div class="uk-margin-bottom uk-width-1-3@m">
                  <h4 class="fieldset_headline">Durchgeführte Arbeiten zur Störungsbehebung</h4>
                  <div class="uk-margin-bottom">
                    <textarea name="durchgefuehrte_arbeiten" class="uk-textarea" cols="100" rows="5" placeholder=""
                      aria-label="Durchgeführte_Arbeiten" v-model="this.form.durchgefuehrte_arbeiten"></textarea>
                  </div>
                </div>

              </div>


            </fieldset>





            <!-- Seite 2 -->

            <fieldset class="uk-fieldset active">

              <div class="inner">

                <div class="uk-margin-bottom">
                  <h4 class="fieldset_headline">Warenschaden</h4>
                  <label>Wurde Warenschaden gemeldet? </label>
                  <div class="select_container">
                    <input v-model="form.warenschaden" class="uk-radio" type="radio" name="warenschaden"
                      value="Nein"><label>NEIN</label>
                    <input v-model="form.warenschaden" class="uk-radio" type="radio" name="warenschaden"
                      value="Ja"><label>JA</label>
                  </div>

                </div>


                <div v-if="this.form.warenschaden == 'Ja'" class="uk-margin-bottom">
                  <p class="note">Beschreibung Warenschaden sowie Vermerk: Temperatur bei Ankunft + Temperatur bei Abreise
                  </p>
                  <textarea class="uk-textarea" name="warenschaden_beschreibung" cols="100" rows="5" placeholder=""
                    aria-label="warenschaden_beschreibung" v-model="this.form.warenschaden_beschreibung"></textarea>
                </div>

              </div>

            </fieldset>

            <fieldset class="uk-fieldset active">

              <div class="inner">

                <div class="uk-margin-bottom">
                  <h4 class="fieldset_headline">Kältemittel</h4>

                  <label>Wurde Kältemittel gefüllt? </label>
                  <div class="select_container">
                    <input v-model="form.kaeltemittel" class="uk-radio" type="radio" name="kaeltemittel"
                      value="Nein"><label>NEIN</label>

                    <input v-model="form.kaeltemittel" class="uk-radio" type="radio" name="kaeltemittel"
                      value="Ja"><label>JA</label>
                  </div>


                </div>


                <div class="uk-width-1-3@m uk-margin-bottom" v-if="this.form.kaeltemittel == 'Ja'">
                  <div class="uk-margin-bottom">
                    <input class="uk-input" name="kaeltemittel_titel" type="text" placeholder="Kältemittel"
                      aria-label="kältemittel" v-model="this.form.kaeltemittel_titel">
                  </div>
                  <div class="uk-margin-bottom">
                    <input class="uk-input" name="kaeltemittel_fuellmenge" type="text" placeholder="Füllmenge (in kg)"
                      aria-label="füllmenge" v-model="this.form.kaeltemittel_fuellmenge">
                  </div>
                </div>

                <div class="uk-margin-bottom">
                  <label>Wurde die Dichtheitsprüfung durchgeführt?</label>
                  <div class="select_container">
                    <input v-model="form.dichtheitspruefung" class="uk-radio" type="radio" name="dichtheitspruefung"
                      value="Nein"><label>NEIN</label>


                    <input v-model="form.dichtheitspruefung" class="uk-radio" type="radio" name="dichtheitspruefung"
                      value="Ja"><label>JA</label>
                  </div>

                </div>

                <div class="uk-margin-bottom">

                  <label>Wurde eine Leckage gefunden? </label>
                  <div class="select_container">
                    <input v-model="form.leckage" class="uk-radio" type="radio" name="leckage"
                      value="Nein"><label>NEIN</label>

                    <input v-model="form.leckage" class="uk-radio" type="radio" name="leckage"
                      value="Ja"><label>JA</label>
                  </div>
                </div>

                <div class="uk-margin-bottom" v-if="this.form.leckage == 'Ja'">
                  <label>Wenn JA, wurde die Leckage behoben? </label>
                  <div class="select_container">
                    <input v-model="form.leckage_behoben" class="uk-radio" type="radio" name="leckage_behoben"
                      value="Nein"><label>NEIN</label>


                    <input v-model="form.leckage_behoben" class="uk-radio" type="radio" name="leckage_behoben"
                      value="Ja"><label>JA</label>
                  </div>

                </div>

                <div class="uk-margin-bottom uk-width-1-3@m" v-if="this.form.leckage == 'Nein'">
                  <label>Wenn NEIN, warum nicht? </label>
                  <textarea class="uk-textarea" cols="100" rows="5" placeholder="" name="leckage_nein_grund"
                    aria-label="leckage_nein_grund" v-model="form.leckage_nein_grund"></textarea>
                </div>

                <div class="uk-margin-bottom">
                  <label>Wurde eine erneute Dichtheitsprüfung durchgeführt? </label>
                  <div class="select_container">
                    <input v-model="form.dichtheitspruefung_erneut" class="uk-radio" type="radio"
                      name="dichtheitspruefung_erneut" value="Nein"><label>NEIN</label>


                    <input v-model="form.dichtheitspruefung_erneut" class="uk-radio" type="radio"
                      name="dichtheitspruefung_erneut" value="Ja"><label>JA</label>
                  </div>


                </div>

              </div>

            </fieldset>


            <div class="infobox uk-margin-bottom uk-width-1-3@m">
              <div class="inner">
                <h4>HINWEIS:</h4>
                <p>Die Dichtheitsprüfung an stationären Kälte- und Klimaanlagen gemäß den Verordnungen (EG) Nr. 1516/2007
                  sowie (EG) Nr. 1005/2009 Artikel 23 bzw. (EU) Nr. 517/2014 Artikel 4 ist immer durchzuführen, wenn
                  Arbeiten am Kältekreislauf durchgeführt wurden oder wenn eine Leckage besteht.</p>
              </div>
            </div>

            <fieldset class="uk-fieldset active">

              <div class="inner">

                <div class="uk-margin-bottom uk-width-1-3@s">
                  <div class="field_highlighted">
                    <label>Auftrag erledigt? </label>
                    <input v-model="form.auftrag_erledigt" class="uk-radio" type="radio" name="auftrag_erledigt"
                      value="Nein"><label>NEIN</label>
                    <input v-model="form.auftrag_erledigt" class="uk-radio" type="radio" name="auftrag_erledigt"
                      value="Ja"><label>JA</label>
                  </div>
                </div>

                <div class="uk-margin-bottom uk-width-1-3@s" v-if="form.auftrag_erledigt == 'Nein'">
                  <label class="m-bot-5">Wenn NEIN, bitte offene Arbeiten eintragen und evtl. Folgetermin und
                    Ansprechpartner eintragen:</label>
                  <textarea name="auftrag_erledigt_offene_arbeiten" class="uk-textarea" cols="100" rows="5" placeholder=""
                    aria-label="auftrag_erledigt_offene_arbeiten"
                    v-model="this.form.auftrag_erledigt_offene_arbeiten"></textarea>
                </div>

                <div class="uk-margin-bottom" v-if="form.auftrag_erledigt == 'Nein'">
                  <label>Wird Fremdfirma benötigt?</label>
                  <input v-model="form.fremdfirma_benoetigt" class="uk-radio" type="radio" name="fremdfirma_benoetigt"
                    value="Nein"><label>NEIN</label>
                  <input v-model="form.fremdfirma_benoetigt" class="uk-radio" type="radio" name="fremdfirma_benoetigt"
                    value="Ja"><label>JA</label>
                </div>

                <div class="uk-margin-bottom" v-if="form.fremdfirma_benoetigt == 'Ja'">
                  <label>Wenn JA, die benötigte Firma/Institution eintragen:</label>
                  <textarea class="uk-textarea" name="fremdfirma" cols="100" rows="5" placeholder=""
                    aria-label="fremdfirma" v-model="this.form.fremdfirma"></textarea>
                </div>

              </div>
            </fieldset>


            <!-- Seite 3-->

            <fieldset class="uk-fieldset active no_bg">

              <div class="inner">

                <div class="uk-margin-bottom">
                  <h4 class="fieldset_headline">Für den Service-Einsatz benutztes Material</h4>
                  <label>Wurde Material vom Auto/Lager benötigt? </label>
                  <div class="select_container">
                    <input v-model="form.material_benoetigt" class="uk-radio" type="radio" name="material_benoetigt"
                      value="Nein"><label>NEIN</label>


                    <input v-model="form.material_benoetigt" class="uk-radio" type="radio" name="material_benoetigt"
                      value="Ja"><label>JA</label>
                  </div>

                </div>

                <div v-if="form.material_benoetigt == 'Ja'" class="uk-margin-bottom">
                  <p>Wenn JA, bitte das Material hier eintragen</p>
                </div>

                <div class="uk-grid-small uk-grid uk-grid-stack">

                  <div class="datagrid uk-width-2-3@m no-margin" v-if="form.material_benoetigt == 'Ja'">

                    <div v-for="item in this.form.material" :key="item.index">

                      <div class="item">

                        <div class="inner uk-grid-small" uk-grid>

                          <div class="uk-width-1-3@s">
                            <label>Beschreibung</label>
                            <input class="uk-input" name="beschreibung" type="text" placeholder="Beschreibung"
                              aria-label="Beschreibung" v-model="item.beschreibung">
                          </div>
                          <div class="uk-width-1-3@s">
                            <label>Artikelnummer</label>
                            <input class="uk-input" name="artikelnummer" type="text" placeholder="Artikelnummer"
                              aria-label="Artikelnummer" v-model="item.artikelnummer">
                          </div>
                          <div class="uk-width-1-3@s">
                            <label>Menge</label>
                            <input class="uk-input" name="menge" type="text" placeholder="Menge" aria-label="Menge"
                              v-model="item.menge">
                          </div>

                        </div>


                        <div class="grid_nav uk-margin-small-top nav-container">
                          <button @click="material_addListItem(this.form.material)"
                            class="uk-button uk-button-secondary uk-margin-small-top uk-button-small uk-margin-small-right"><span
                              class="uk-icon" uk-icon="plus-circle"></span>Eintrag hinzufügen</button>
                          <button @click="material_removeListItem(this.form.material, item, index)"
                            class="uk-button uk-button-secondary uk-margin-small-top uk-button-small"><span
                              class="uk-icon" uk-icon="minus-circle"></span>Eintrag löschen</button>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </fieldset>

            <fieldset class="uk-fieldset active">

              <div class="inner">
                <div class="uk-grid-small" uk-grid>

                  <div class="uk-margin-bottom">
                    <label class="uk-margin-small-right">Wurde Material vom Lieferschein benötigt </label>
                    <div class="select_container">
                      <input v-model="form.material_lieferschein_benoetigt" class="uk-radio" type="radio"
                        name="material_lieferschein_benoetigt" value="Nein"><label>NEIN</label>


                      <input v-model="form.material_lieferschein_benoetigt" class="uk-radio" type="radio"
                        name="material_lieferschein_benoetigt" value="Ja"><label>JA</label>
                    </div>
                  </div>



                </div>

                <div class="uk-margin-bottom uk-width-1-3@m" v-if="form.material_lieferschein_benoetigt == 'Ja'">
                  <input class="uk-input" type="text" name="material_lieferscheinnr" placeholder="Lieferschein-Nr.:"
                    aria-label="lieferscheinnr" v-model="this.form.material_lieferscheinnr">
                </div>

              </div>

            </fieldset>

            <fieldset class="uk-fieldset active">

              <div class="inner">
                <div class="uk-grid-small" uk-grid>

                  <div>
                    <label>War das gelieferte Material defekt?</label>
                    <div class="select_container">
                      <input v-model="form.material_defekt" class="uk-radio" type="radio" name="material_defekt"
                        value="Nein"><label>NEIN</label>

                      <input v-model="form.material_defekt" class="uk-radio" type="radio" name="material_defekt"
                        value="Ja"><label>JA</label>
                    </div>

                  </div>



                </div>

                <div class="uk-margin-top uk-width-1-3@m" v-if="form.material_defekt == 'Ja'">
                  <label>Wenn Ja, bitte um die genaue Defekt-Beschreibung</label>
                  <textarea class="uk-textarea" cols="100" rows="5" placeholder=""
                    aria-label="material_defekt_beschreibung" v-model="material_defekt_beschreibung"></textarea>
                </div>

              </div>

            </fieldset>

            <fieldset class="uk-fieldset active">

              <div class="inner">
                <div class="uk-grid-small" uk-grid>

                  <div class="uk-margin-top">
                    <label class="uk-margin-small-right uk-width-1-2@s">Wurde Material zurückgenommen?</label>
                    <div class="select_container">
                      <input v-model="form.material_zurueckgenommen" class="uk-radio" type="radio"
                        name="material_zurueckgenommen" value="Nein"><label>NEIN</label>


                      <input v-model="form.material_zurueckgenommen" class="uk-radio" type="radio"
                        name="material_zurueckgenommen" value="Ja"><label>JA</label>
                    </div>

                  </div>
                </div>

                <div class="uk-margin-bottom uk-margin-top uk-width-1-3@m" v-if="form.material_zurueckgenommen == 'Ja'">
                  <label>Wenn Ja, bitte um Info was zurückgenommen wurde</label>
                  <textarea class="uk-textarea" cols="100" rows="5" placeholder=""
                    name="material_zurueckgenommen_beschreibung" aria-label="material_zurueckgenommen_beschreibung"
                    v-model="form.material_zurueckgenommen_beschreibung"></textarea>
                </div>

                <div class="uk-margin-bottom uk-margin-top uk-width-1-3@m">
                  <label>Sonstiges/Anmerkungen</label>
                  <textarea class="uk-textarea" name="sonstige_anmerkungen" cols="100" rows="5" placeholder=""
                    aria-label="sonstige_anmerkungen" v-model="this.form.sonstige_anmerkungen"></textarea>
                </div>

              </div>

            </fieldset>


            <fieldset class="uk-fieldset active">

              <div class="inner uk-margin-bottom">
                <h4 class="fieldset_headline">Zeiterfassung Techniker</h4>
                <div>
                  <label class="uk-margin-right"><input class="uk-checkbox" name="noteinsatz" type="checkbox"
                      v-model="this.form.noteinsatz">Noteinsatz</label>
                  <label class="uk-margin-right"><input class="uk-checkbox" name="nachteinsatz" type="checkbox"
                      v-model="this.form.nachteinsatz">Nachteinsatz</label>
                </div>
              </div>

            </fieldset>


            <fieldset class="uk-fieldset active no_bg ">

              <div class="datagrid">
                <div class="inner">
                  <div class="uk-grid-small uk-grid uk-grid-stack uk-width-1-2@s" uk-grid>


                    <div v-for="(item, index) in this.form.zeiterfassung" :key="item.index">

                      <div class="item">
                        <div class="inner">

                          <div class="uk-grid-small" uk-grid>

                            <div class="field_container uk-width-1-3@m">
                              <label>Monteur</label>
                              <input class="uk-input" type="text" name="monteur" 
                                aria-label="Genaue Beschreibung" v-model="item.monteur">
                            </div>

                            <div class="field_container uk-width-1-3@m">
                              <label>Datum</label>
                              <input class="uk-input" type="text" name="datum" aria-label="Datum" v-model="item.datum">
                            </div>

                            <div class="field_container uk-width-1-3@m">
                              <label>Arbeitszeit von</label>
                              <input class="uk-input" type="text" name="arbeitszeit_von" aria-label="Arbeitszeit von"
                                v-model="item.arbeitszeit_von">
                            </div>
                          </div>

                          <div class="uk-grid-small" uk-grid>

                            <div class="field_container uk-width-1-3@m">
                              <label>Arbeitszeit bis</label>
                              <input class="uk-input" type="text" name="arbeitszeit_bis" aria-label="Arbeitszeit bis"
                                v-model="item.arbeitszeit_bis">
                            </div>

                            <div class="field_container uk-width-1-3@m">
                              <label>Fahrzeit von</label>
                              <input class="uk-input" type="text" name="fahrzeit_von" aria-label="Fahrzeit von"
                                v-model="item.fahrzeit_von">
                            </div>

                            <div class="field_container uk-width-1-3@m">
                              <label>Fahrzeit bis</label>
                              <input class="uk-input" type="text" name="fahrzeit_bis" aria-label="Fahrzeit bis"
                                v-model="item.fahrzeit_bis">
                            </div>

                          </div>

                          <div class="uk-grid-small" uk-grid>
                            <div class="field_container uk-width-1-3@m">
                              <label>Kilometer</label>
                              <input class="uk-input" type="text" name="kilometer" aria-label="Kilometer"
                                v-model="item.kilometer">
                            </div>
                          </div>

                        </div>




                        <div class="grid_nav nav-container m-top-150">
                          <button @click="zeiterfassung_addListItem(this.form.zeiterfassung)"
                            class="uk-button uk-button-secondary uk-button-small uk-margin-small-right uk-margin-bottom"><span
                              class="uk-icon" uk-icon="plus-circle"></span>Neuen Eintrag hinzufügen</button>
                          <button @click="zeiterfassung_removeListItem(this.form.zeiterfassung, item, index)"
                            class="uk-button uk-button-secondary uk-button-small uk-margin-bottom"><span class="uk-icon"
                              uk-icon="minus-circle"></span>Eintrag löschen</button>
                        </div>

                      </div>
                  
                      <div class="uk-grid-small uk-margin-bottom" uk-grid>
                          <div class="field_container uk-width-1@m">
                            <h4 class="fieldset_headline">Fotos</h4>
                            <p class="tinynote">Die Anzahl der Fotos ist auf 40 Bilder beschränkt.</p>
                            <div class="file_uploader">
                              <Uploader :media="media.saved" :myindex=index :server=this.file_uploader_path @add="addMedia"
                                @remove="removeMedia" :max=40 :maxFilesize=15 :inputId="'mu-file-input-' + index" />    
                            </div>
                          </div>
                     </div>

                     <div class="uk-grid uk-grid-stack uk-margin-bottom">

                      <div class="uk-width-1-2@m">
                        <input class="uk-input uk-margin-bottom" name="marktleiter_name" type="text"
                          placeholder="Name Marktleiter (BLOCKBUCHSTABEN)" aria-label="Name Marktleiter (BLOCKBUCHSTABEN)"
                          v-model="this.form.marktleiter_name" required>
                      </div>

                      <div class="uk-width-1-2@m">
                        <input class="uk-input" name="servicetechniker_name" type="text"
                          placeholder="Name Service Techniker (BLOCKBUCHSTABEN)"
                          aria-label="Name Service Techniker (BLOCKBUCHSTABEN)" v-model="this.form.servicetechniker_name"
                          required>
                      </div>

                    </div>
                 
                    </div>
                  </div>

                </div>
              </div>

            </fieldset>




            <div class="form_buttons">
              <button class="uk-button uk-button-primary uk-button-small submit-btn">Bearbeitung abschliessen</button>
            </div>

            <div v-if="this.showHint == true" class="submit-check-hint">
              <p>Überprüfen Sie bitte noch mal alle Ihre Daten auf korrekte Eingabe. Eine nachträgliche Bearbeitung ist
                nur vom Büro möglich.</p>
              <button @click="backToForm()">Zurück zur Bearbeitung</button>
              <button @click="sendFormFinal()">Formular absenden</button>
            </div>
          </div>




        </div>
      </div>
    </form>


    <!-- 1 -->
    <div v-if="this.mainFormActive != true">
      <div class="form_success_screen uk-grid">
        <div class="uk-width-1-1@m">
          <h3 class="status">{{ this.status }}</h3>
          <div class="centered button_container">
            <button @click="backToOverview">Zurück zur Formularauswahl</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 1 -->

  </div>
</template>

<script>

import axios from 'axios'
import { reactive } from 'vue'

export default {
  name: 'FormArbeitsberichte',

  setup() {
    // make users variable reactive with the ref() function

   
    const form = reactive(
    
      {
        typ: '',
        datum: "",
        serviceticketnr: '',
        mobile_c_nr: '',
        tochtergesellschaft: '',
        name_sp: '',
        rueckmeldenr: '',
        adresse: '',
        anmerkungen: '',
        durchgefuehrte_arbeiten: '',
        warenschaden: 'Nein',
        warenschaden_beschreibung: null,
        kaeltemittel: 'Nein',
        kaeltemittel_titel: null,
        kaeltemittel_fuellmenge: null,
        leckage: 'Nein',
        leckage_behoben: '',
        userid: null,
        leckage_nein_grund: '',
        dichtheitspruefung: 'Nein',
        dichtheitspruefung_erneut: 'Nein',
        auftrag_erledigt: null,
        auftrag_erledigt_offene_arbeiten: null,
        fremdfirma_benoetigt: 'Nein',
        fremdfirma: null,
        lieferscheinnr: null,
        material_benoetigt: null,
        material_lieferschein_benoetigt: null,
        material_defekt: 'Nein',
        material_defekt_beschreibung: null,
        material_zurueckgenommen: 'Nein',
        material_zurueckgenommen_beschreibung: '',
        sonstige_anmerkungen: '',
        zeiterfassung: [{
          "monteur": "",
          "datum": "",
          "arbeitszeit_von": "",
          "arbeitszeit_bis": "",
          "fahrzeit_von": "",
          "fahrzeit_bis": "",
          "kilometer": ""
        }],
        material: [{
          "beschreibung": "",
          "artikelnummer": "",
          "menge": ""
        }],
        media: {
          saved: [
           
          ],
          added: [],
          removed: []
        },
        servicetechniker_name: "",
        marktleiter_name: "",

      }

    );
   





   /*
    const form = reactive(

      {
        typ: 'Montage',
        datum: "22.10.2022",
        serviceticketnr: '001',
        mobile_c_nr: '0001',
        tochtergesellschaft: 'Meine Gesellschaft',
        name_sp: 'Hands4-U GmbH',
        rueckmeldenr: '0002',
        adresse: 'Teststr.',
        anmerkungen: 'Meine Anmerkung',
        durchgefuehrte_arbeiten: '',
        warenschaden: 'Nein',
        warenschaden_beschreibung: null,
        kaeltemittel: 'Nein',
        kaeltemittel_titel: null,
        kaeltemittel_fuellmenge: null,
        leckage: 'Nein',
        leckage_behoben: '',
        userid: null,
        leckage_nein_grund: '',
        dichtheitspruefung: 'Nein',
        dichtheitspruefung_erneut: 'Nein',
        auftrag_erledigt: null,
        auftrag_erledigt_offene_arbeiten: null,
        fremdfirma_benoetigt: 'Nein',
        fremdfirma: null,
        lieferscheinnr: null,
        material_benoetigt: null,
        material_lieferschein_benoetigt: null,
        material_defekt: 'Nein',
        material_defekt_beschreibung: null,
        material_zurueckgenommen: 'Nein',
        material_zurueckgenommen_beschreibung: '',
        sonstige_anmerkungen: '',
        zeiterfassung: [{
          "monteur": "Monteur 1",
          "datum": "01.01.2023",
          "arbeitszeit_von": "9:00",
          "arbeitszeit_bis": "10:00",
          "fahrzeit_von": "8:00",
          "fahrzeit_bis": "9:00",
          "kilometer": "500"
        }],
        material: [{
          "beschreibung": "",
          "artikelnummer": "",
          "menge": ""
        }],
        media: {
          saved: [],
          added: [],
          removed: []
        },
        servicetechniker_name: "Servicetechniker-Mustermann",
        marktleiter_name: "Marktleiter-Mustermann",
      }

    );
    */
    


    return { form }
  },

  data: function () {
    return {
      mainFormActive: true,
      overviewPage: "https://hands4you.codepreview.de/formulare",
      status: "Danke Ihr Auftrag wurde gespeichert.",
      currentID: null,
      currentUser: null,
      showHint: false,

      media: {
          saved: [],
          added: [],
          removed: []
      },
      formID: Date.now(),
      file_uploader_path: "",
      upload_formular: 'arbeitsbericht'

    }
  },

  mounted() {

    this.file_uploader_path = 'https://hands4you.codepreview.de/files/hands4you/php/file_upload.php?id=' + this.formID + "&formular=" + this.upload_formular;

    this.form.datum = this.getCurrentDate();
   
    if (typeof userid !== 'undefined') {
      this.form.userid = userid;
    }

    if (typeof document.getElementById('meta-app-user-lastname') !== undefined && document.getElementById('meta-app-user-lastname') != null) {
      this.currentUser = {
        id: document.getElementById('meta-app-user-lastname').content,
        lastname: document.getElementById('meta-app-user-lastname').content,
        firstname: document.getElementById('meta-app-user-firstname').content
      }
    }
  },

  methods: {

    sendForm: async function () {
      this.showHint = true;
    },

    addMedia(addedImage, addedMedia) {
      //this.media.added = addedMedia;
      this.form.media.saved.push(addedMedia.name);
      console.log("media: "+this.form.media.saved)
    },

    removeMedia(removedImage, removedMedia){
        this.media.removed = removedMedia;  
        let url = 'https://hands4you.codepreview.de/files/hands4you/php/remove_image.php';
        
        // Create a new FormData object
        const formData = new FormData();

        // Append the file to the FormData object
        formData.append('myFile', removedMedia.name);

        // Make a POST request using Axios
        axios.post(url, formData)
          .then(response => {
            // Handle the response from the PHP script
            console.log(response.data);
          })
          .catch(error => {
            // Handle any errors
            console.error(error);
          });
    },

    getUserID: async function () {
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/form_arbeitsbericht.php';
      try {
        let payload = JSON.stringify(this.form);
        const { data } = await axios.post(url, payload);
      } catch (e) {
        console.log(e);
      }

      /*
      axios.post(url, {
        data: this.form,
      })
      .then(function (response) {
        console.log("YAH OK: "+response.data)
      })
      .catch(function (error) {
        console.log("NO NOT: "+error)
      })
      */
    },

    createPDF: async function () {
      console.log("createPDF");
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/generatePDF.php';
      let myData =
      {
        id: this.currentID,
        formular_detail_ansicht_url: "https://hands4you.codepreview.de/formulare/arbeitsbericht/detailansicht/"
      }
      let payload = JSON.stringify(myData);
      try {
        // const {returnValue} = await axios.post(url, payload);
        // console.log(returnValue);

        axios.post(url,
          payload,
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/pdf'
            }
          })
          .then((response) => {

            // Er erstellt einen Link zu dem PDF und führt dann den Klick auf den Link aus, abgefahren

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let myFileName = "";

            if (this.currentUser != null) {
              myFileName = "arbeitsbericht_" + this.currentUser.lastname + "_" + this.currentID + ".pdf";
            } else {
              myFileName = "arbeitsbericht_" + this.currentID + ".pdf";
            }


            link.setAttribute('download', myFileName); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => console.log(error));
        this.status = "Die PDF wurde generiert.";

      } catch (e) {
        console.log(e);
      }
    },

    backToOverview: function () {
      window.open(this.overviewPage, "_self")
    },

    zeiterfassung_addListItem(dataObj) {
      dataObj.push(
        {
          "monteur": "",
          "datum": "24.10.2022",
          "arbeitszeit_von": '',
          "arbeitszeit_bis": '',
          "fahrzeit_von": '',
          "fahrzeit_bis": '',
          "kilometer": ""
        }
      )
    },

    getCurrentDate() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();

      today = dd + "." + mm + "." + yyyy;
      return today;
    },

    zeiterfassung_removeListItem(dataObj, item, index) {

      // Doesnt work in Vue 3
      //this.$delete(item,index);

      dataObj.splice(index, 1)
    },

    material_addListItem(dataObj) {
      dataObj.push(
        {
          beschreibung: '',
          artikelnummer: '',
          mange: ''
        }
      );
    },

    material_removeListItem(dataObj, item, index) {

      // Doesnt work in Vue 3
      //this.$delete(item,index);

      dataObj.splice(index, 1)
    },

    createFormData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },

    backToForm: function () {
      this.showHint = false;
    },
    sendFormFinal: async function () {
      console.log("sendFormFinal")
      let url = 'https://hands4you.codepreview.de/files/hands4you/php/form_arbeitsbericht.php';

      try {
        let payload = JSON.stringify(this.form);
        const { data } = await axios.post(url, payload);
        console.log(data);

        // The script returns the id of the just created dataset
        this.currentID = data;
        console.log("NEW ID: " + this.currentID);

        window.scrollTo(0, 0);
        this.mainFormActive = false;
      } catch (e) {
        console.log(e);
      }
    }


  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
