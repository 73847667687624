<template>
  <div>
    <div class="header">
        <div v-if="openPage === 'Arbeitsberichte'" class="heading"><h1 class="uk-heading">Arbeitsbericht</h1><div class="version-nr">Version: {{ this.version }}</div></div>
        <div v-if="openPage === 'Mängelliste'" class="heading"><h1 class="uk-heading">Mängelliste</h1><div class="version-nr">Version: {{ this.version }}</div></div>
        <div v-if="openPage === 'Prüfprotokoll_SPIConnect'" class="heading"><h1 class="uk-heading">Prüfprotokoll - LIDL SPI Connect</h1><div class="version-nr">Version: {{ this.version }}</div></div>
        <div v-if="openPage === 'Prüfprotokoll_Schiebetueren'" class="heading"><h1 class="uk-heading">Prüfprotokoll - NK-Zelle mit Monoblock R290 mit Schiebetüren</h1><div class="version-nr">Version: {{ this.version }}</div></div>
        <div v-if="openPage === 'Prüfprotokoll_Rolltore'" class="heading"><h1 class="uk-heading">Prüfprotokoll - NK-Zelle mit Monoblock R290 mit Rolltor</h1><div class="version-nr">Version: {{ this.version }}</div></div>
        <div v-if="openPage === 'Wartungsprotokoll_RMG_CCP_Connect'" class="heading"><h1 class="uk-heading">Wartungsprotokoll RMG</h1><div class="version-nr">Version: {{ this.version }}</div></div>
        <div v-if="openPage === 'Wartungsprotokoll_HauserHybrid'" class="heading"><h1 class="uk-heading">Hauser Hybridregal (Wandlkühlregal Steckerfertig R134a / R774 Connect 2 Luft-
Wasser)</h1><div class="version-nr">Version: {{ this.version }}</div></div>
        <div v-if="openPage === 'Wartungsprotokoll_HauserSlimline'" class="heading"><h1 class="uk-heading">Hauser Slimline (Hauser Wandkühlregal Steckerfertig CA744)</h1><div class="version-nr">Version: {{ this.version }}</div></div>
     </div>
    
    <div class="form_content">
      <FormArbeitsberichte v-if="openPage === 'Arbeitsberichte'"></FormArbeitsberichte>
      <FormMaengelliste v-if="openPage === 'Mängelliste'"></FormMaengelliste>
      <FormPruefprotokoll_SPIConnect v-if="openPage === 'Prüfprotokoll_SPIConnect'"></FormPruefprotokoll_SPIConnect>
      <FormPruefprotokoll_Schiebetueren v-if="openPage === 'Prüfprotokoll_Schiebetueren'"></FormPruefprotokoll_Schiebetueren>
      <FormPruefprotokoll_Rolltore v-if="openPage === 'Prüfprotokoll_Rolltore'"></FormPruefprotokoll_Rolltore>
      <FormWartungsprotokoll_RMG_CCP_Connect v-if="openPage === 'Wartungsprotokoll_RMG_CCP_Connect'"></FormWartungsprotokoll_RMG_CCP_Connect>
      <FormWartungsprotokoll_Hauser_Hybrid v-if="openPage === 'Wartungsprotokoll_HauserHybrid'"></FormWartungsprotokoll_Hauser_Hybrid>
      <FormWartungsprotokoll_Hauser_Slimline v-if="openPage === 'Wartungsprotokoll_HauserSlimline'"></FormWartungsprotokoll_Hauser_Slimline>
    </div>

    <div class="footer">
        <div class="version"><p>Version: {{ this.version }}</p></div>
    </div>
  </div>
</template>

<script>
import FormArbeitsberichte from './components/FormArbeitsberichte.vue'
import FormMaengelliste from './components/FormMaengelliste.vue'
import FormPruefprotokoll_SPIConnect from './components/FormPruefprotokoll_SPIConnect.vue'
import FormPruefprotokoll_Schiebetueren from './components/FormPruefprotokoll_Schiebetueren.vue'
import FormPruefprotokoll_Rolltore from './components/FormPruefprotokoll_Rolltore.vue'
import FormWartungsprotokoll_RMG_CCP_Connect from './components/FormWartungsprotokoll_RMG_CCP_Connect.vue'
import FormWartungsprotokoll_Hauser_Hybrid from './components/FormWartungsprotokoll_Hauser_Hybrid.vue'
import FormWartungsprotokoll_Hauser_Slimline from './components/FormWartungsprotokoll_Hauser_Slimline.vue'

export default {
  name: 'App',

  components: {
    FormArbeitsberichte,
    FormMaengelliste,
    FormPruefprotokoll_SPIConnect,
    FormPruefprotokoll_Rolltore,
    FormPruefprotokoll_Schiebetueren,
    FormWartungsprotokoll_RMG_CCP_Connect,
    FormWartungsprotokoll_Hauser_Hybrid,
    FormWartungsprotokoll_Hauser_Slimline
  },
 
  mounted() {
    console.log("MODE: "+process.env.NODE_ENV)
    if(process.env.NODE_ENV === 'production') {
        this.openPage = document.getElementById('app-page').textContent;
    } 
  },

  data() {
      return {
          version: "1.43",
          openPage: 'Arbeitsberichte',
          //openPage: 'Mängelliste',
          //openPage: 'Prüfprotokoll_SPIConnect',
          //openPage: 'Prüfprotokoll_Rolltore',
          //openPage: 'Prüfprotokoll_Schiebetueren',
          //openPage: 'Wartungsprotokoll_RMG_CCP_Connect',
          //openPage: 'Wartungsprotokoll_HauserHybrid',
          //openPage: 'Wartungsprotokoll_HauserSlimline'
      }
    },
}
</script>

<style lang="scss">
 
  $white: #FFF;
  $black: #000;

  $global-link-color: #DA7D02;

  $darkGrey: #333;
  $mediumGrey: #666;

  $ci_grey:#323232;
  $ci_light_blue: #0073BB;
  $ci_light_blue_hover: #0094F0;
  $ci_blue: #00538E;

  $pageDarkGrey: #35363A;
  $pageLightGrey: #c8c8c8;
  $contentDarkestGrey: #18181a;
  $tileBGColor: #4A5161;
 
  $ci_grey_transparent: rgba(0,0,0,0.15);

  // 2. Import default variables and available mixins.
  @import "uikit/src/scss/variables-theme.scss";
  @import "uikit/src/scss/mixins-theme.scss";

  // 3. Your custom mixin overwrites.
  @mixin hook-card() { 
    color: #000; 
  }

  // 4. Import UIkit.
  @import "uikit/src/scss/uikit-theme.scss";

  // Google Fonts
  @import url('https://fonts.googleapis.com/css?family=Roboto');

  #app {
    font-family: 'Roboto', sans-serif;

    * {
      font-family: 'Roboto', sans-serif;
    }

    h1, h2 {
      color: $white;
    }
    p.tinynote {
      font-size: 0.8em;
      margin: 0;
      padding: 0;
      margin-bottom: 0.5em;
    }

    .version-nr {
      position: relative;
      top: -7px;
      right: 10px;
      text-align: right;
    }

    .select_container {
        display: inline-block;
      }

    .no-margin-bot {
      margin-bottom: 0;
    }

    .testmodus_note {
      font-size: 0.8em;
      margin: 0;
      padding: 0;
    }

    .testmodus_headline {
      margin: 0!important;
      padding: 0!important;
    }

    
    .file_uploader {
      .mu-container {
        border-radius: 0!important;
        background-color: $pageLightGrey!important;

        .mu-image-container {
          margin: 0!important;
          padding: 0!important;
          margin-left: 0.5em!important;
        }

        .mu-plusbox-container:hover {
          label.mu-plusbox {
              background-color: $ci_blue!important;
            }
        }

        .mu-plusbox {
          padding: 0;
          margin: 0;

          svg {
            path {
              color: $white;
            }
          }
        }

        .mu-plusbox-container {
          margin: 0!important;
        }



        .mu-plus-icon {
          width: 48px;
          height: 48px;
          fill {
            color: $white;
          }
        }
        .mu-plusbox, .mu-image-container {
          border-radius: 0!important;
          border: 0!important;
          background-color: $darkGrey!important;
        }

      }
    }
  

    .field_container_headline {
      display: block;
      background-color: $mediumGrey;
      color: $white;
      padding: 0.25em;
    }

    .centered {
      margin: auto;
      text-align: center;
    }

    button {
      font-family: 'Roboto', sans-serif;
      background-color: $contentDarkestGrey;
      border: 0;
      padding: 0.75em;
      margin-right: 0.5em;
      border: 1px solid $mediumGrey;
      cursor: pointer;
      font-size: 1.2em;
      font-weight: 300;

      &:hover {
        background-color: $tileBGColor;
      }
    }

    ::-webkit-calendar-picker-indicator {
        filter: brightness(50%) invert(100%);
    }

    textarea,input {
      background-color: $contentDarkestGrey;
    }

    .submit-btn {
      min-width: 220px;
    }

    .uk-button-small {
        font-size: 1em;
        padding: 0.35em 0.5em;
    }

    .form_success_screen {
      padding: 2em 0;
      
      .button_container {
        margin-top: 1em;
      }
      
      * {
        color: $white;
      }

      h3 {
        text-align: center;
      }
    }

    .form_content {
      padding-top: 1em;
      background-color: $pageDarkGrey;
      min-height: 100vh;
      .status {
        margin-bottom: 1.5em;
      }
    }

    .box_highlighted_darkgrey {
      
      margin: 1em 0;
      margin-top: 0;
      p {
        margin: 0;
        padding: 1em 0.5em;
        background-color: $pageLightGrey;
        color: $darkGrey;
      }
    }

    .no-margin-bot {
      margin-bottom: 0;
    }

    .no-margin-top {
      margin-top: 0;
    }

    .uk-radio {
      margin-right: 0.25em;
      margin-left: 0.25em;
    }

    .checkbox-label {
      margin-right: 0.5em;
    }
   
    input.uk-checkbox, input.uk-checkbox:focus {
      margin-right: 0.5em;
    }

    .infobox {
      font-size: 0.9em;
      margin: 0 1em; 
      color: $white;

      .inner {
        padding: 0.75em;
        border: 1px solid $pageLightGrey;
        
        h4 {
          color: $white;
          font-size: 1.1em;
          margin-bottom: 0.25em;
        }
        
        p {
          margin: 0;
        }
      }
    }

    p, p.note {
      color: $white;
      margin-bottom: 0;
    }

    .form_buttons {
      color: $white;
      margin-bottom: 1em;
      width: 100%;
    }
    .header {
      position: relative;
      background-color: $contentDarkestGrey;
      color: $white;
      min-height: 50px;
      text-align: center;

      .heading {   
        h1 {
          position: relative;
          top: 14px;
          font-size: 1.3em;
          text-transform: uppercase;

        }

      }

      h1, h2, h3, h4, h5 {
        color: $white;
      }
     
    }

    .field_highlighted {
      background-color: $contentDarkestGrey;
      color: $white;

      padding: 0.5em 0;
      padding-left: 0.5em;
      input:focus {
        background-color: #60750c;
      }
    }

    .footer {
      position: relative;
      background-color: $contentDarkestGrey;
      color: $white;
      font-size: 0.9em;
      min-height: 50px;
      text-align: center;
      .version {
        position: relative;
        top: 13px; 
      }
    }  

    h1, h2, h3, h4, h5 {
      margin: 0;
    }


  .uk-button {
    .uk-icon {
      margin-right: 0.5em;
    }
  }

  .uk-button-secondary {
    background-color: $tileBGColor;
    &:hover {
      background-color: $ci_light_blue_hover;
    }
  }

  .nav-container {
    margin: 0;
    button {
      margin-left: 0.5em;
    }
  }

  .field_container {
    label {
      display: inline-block;
      margin: 0.25em 0;
    }
  }

  .uk-radio + label {
    margin-right: 0.25em;
  }

  .uk-radio:checked {
    background-color: #000;
  }

  .datagrid {
        
    margin-left: 0.5em;

    .inner {
      padding: 0 0.5em;
    }

    .uk-button {
      margin: 0;
      margin-left: 0.25em;      
    }

    button {
      font-size: 0.9em;
    }

    .grid_nav {
      span {
        cursor: pointer;
      }
    }

    .item {
      background-color: $contentDarkestGrey;
      padding: 1.25em 1em;
      margin-bottom: 1em;

      input,textarea {
        background-color: $pageLightGrey;
        color: $black;

        &::placeholder {
           color: $black;
        }

        &:active, &:focus {
          background-color: $ci_blue;
          color: $white;
          &::placeholder {
           color: $white;
        }
        }
      }
    }

  }

    fieldset {
      display: none;
      width: 100%;    
      

      &.no_bg {
        .inner {
          background-color: transparent;
        }
      }

      div.note {
        color: $white;
        font-size: 0.8em;
        margin: 0 0.5em;
      }

      input, textarea {

        transition: all 0.2s ease-out;

        &:focus {
          background-color: $ci_light_blue;
          color: $white;
          &::placeholder {
           color: $white;
          }
        }
       
      }

      .inner {
         
        padding: 0 1em;

        h3 {
          margin-top: 0.5em;
        }

        .fieldset_headline {
          color: $white;
          margin: 0.5em 0;
          margin-top: 0;
        }
        label {
          color: $white;
        }
      }
      &.active {
        display: block;
      }
    }


  /* Helper */
    
  .m-top-25 {
    margin-top: 0.25em;
  }

  .m-top-50 {
    margin-top: 0.5em;
  }

  .m-top-75 {
    margin-top: 0.75em;
  }

  .m-top-100 {
    margin-top: 1em;
  }

  .m-top-125 {
    margin-top: 1.25em;
  }

  .m-top-150 {
    margin-top: 1.5em;
  }

  .m-top-175 {
    margin-top: 1.75em;
  }

  .m-top-200 {
    margin-top: 2em;
  }

  .m-bottom-25 {
    margin-bottom: 0.25em;
  }

  .m-bottom-50 {
    margin-bottom: 0.5em;
  }

  .m-bottom-75 {
    margin-bottom: 0.75em;
  }

  .m-bottom-100 {
    margin-bottom: 1em;
  }

  .m-bottom-125 {
    margin-bottom: 1.25em;
  }

  .m-bottom-150 {
    margin-bottom: 1.5em;
  }

  .m-bottom-175 {
    margin-bottom: 1.75em;
  }

  .m-bottom-200 {
    margin-bottom: 2em;
  }

  .no-margin {
    margin: 0;
  }

  .no-padding {
    padding: 0;
  }

  .submit-check-hint {
    position: fixed;
    bottom:0;
    left: 0;
    display: block;
    width: calc(100% - 2px);
    box-sizing: border-box;
    padding: 1em;
    background-color: $contentDarkestGrey;
    border-top: 3px solid $black;
    z-index: 10001;
    color: $white;

    p {
      margin-bottom: 0.5em;
    }

    button {
      background-color: $black;
      color: $pageLightGrey;
      font-size: 0.8em;
      border: 1px solid $mediumGrey;
      &:hover {
        background-color: $mediumGrey;
        color: $white;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
    /* CSS-Regeln hier platzieren */
    body #app {
         
      .header {
        min-height: auto;
        padding-bottom: 1.5em;
      }

      .form_buttons {
        text-align: center;
      }

      .select_container {
        display: block;
      }

      label+.select_container {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
      
      .header .heading h1 {
        font-size: 1.3em;
      }

      .heading {
        padding: 1em 0;
        .uk-heading {
          max-width: 90%;

        }
      }
    }
  }

</style>
