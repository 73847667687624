import App from './App.vue'
import { createApp } from 'vue';

UIkit.use(Icons)
window.UIkit = UIkit

import UIkit from 'uikit'
import '@/assets/styles/styles.scss'
import Icons from 'uikit/dist/js/uikit-icons'

import Uploader from 'vue-media-upload'

const app = createApp(App);
app.component('Uploader', Uploader);
const mountedApp = app.mount('#app');